import { getBaseUrl } from "@vinsolutions/core/environment";
import { fetchWrapper } from "@vinsolutions/core/http";
import { createLogger } from "@vinsolutions/logger";
import { DealApiConfig } from "./crm.workflow.deal.api.config";
import { DealSettingsResponse } from "./models/deal-settings-response.model";
import { CustomerDashboardSettingsResponse } from "./models/customer-dashboard-settings-response.model";
import { DealSettings } from "./models/deal-settings.model";

const logger = createLogger("data-access-workflow-crm.workflow.deal.api");

export const getDealSettings = async (
  leadId: number,
  dealerId: number,
  jwt: string
): Promise<DealSettingsResponse | null | undefined> => {
  if (leadId <= 0 || dealerId <= 0) {
    logger.error(
      "Error getting deal settings.",
      "The leadId and dealerId must have values ​​greater than 0."
    );
    return null;
  }
  const dealSettingsUrl = getDealSettingsUrl(leadId, dealerId);
  const response = await fetchWrapper.getAsync<DealSettingsResponse>(
    dealSettingsUrl,
    {
      Authorization: `Bearer ${jwt}`,
      Accept: "application/vnd.coxauto.v1+json",
      "Content-Type": "application/vnd.coxauto.v1+json"
    }
  );

  switch (response.statusCode) {
    case 200:
      return response.data;
    case 404:
      return null;
    default: {
      if (response.statusCode === 403) {
        logger.debug("", JSON.stringify(response));
      } else {
        logger.error("Error getting deal settings.", JSON.stringify(response));
      }
      return null;
    }
  }
};

export const getCustomerDashboardSettings = async (
  dealerId: number,
  leadId: number,
  vdpLink: string | null,
  jwt: string
): Promise<CustomerDashboardSettingsResponse | null | undefined> => {
  if (leadId <= 0 || dealerId <= 0) {
    logger.error(
      "Error getting deal settings.",
      "The leadId and dealerId must have values ​​greater than 0."
    );
    return null;
  }
  try {
    const callUrl = getCustomerDashboardDealSettingsUrl(
      leadId,
      dealerId,
      vdpLink
    );

    const response = await fetchWrapper.getAsync<DealSettings>(callUrl, {
      Authorization: `Bearer ${jwt}`,
      Accept: "application/vnd.coxauto.v1+json",
      "Content-Type": "application/vnd.coxauto.v1+json"
    });

    return {
      hasError: false,
      message: "",
      data: response.data
    } as CustomerDashboardSettingsResponse;
  } catch (error: any) {
    return {
      hasError: true,
      message: error.message,
      data: {
        isAccelerateDropdownButtonVisible: false,
        isCopyLinkButtonVisible: false
      }
    } as CustomerDashboardSettingsResponse;
  }
};

export function getDealSettingsUrl(leadId?: number, dealerId?: number): string {
  const baseUrl = getBaseUrl(DealApiConfig.baseUrls);
  return `${baseUrl}from/digital-retail/deal/settings?leadId=${leadId}&dealerId=${dealerId}`;
}

export function getCustomerDashboardDealSettingsUrl(
  leadId?: number,
  dealerId?: number,
  vdpLink?: string | null
): string {
  const baseUrl = getBaseUrl(DealApiConfig.baseUrls);
  let callUrl = `${baseUrl}from/digital-retail/deal/settings/customer-dashboard?leadId=${leadId}&dealerId=${dealerId}`;
  if (vdpLink) {
    callUrl += `&vdpLink=${encodeURIComponent(vdpLink)}`;
  }
  return callUrl;
}
