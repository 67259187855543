import { DealSettings } from "@vinsolutions/data-access/workflow/crm.workflow.deal.api";
import { ModalContext } from "./interfaces/modal-context";
import { DealInteractionDropdownSettings } from "./interfaces/deal-interaction-dropdown-settings";
import { CopyLinkSettings } from "./interfaces/deal-interaction-copy-link-settings";

export const buildAccelerateObject = (
  dealSettings: DealSettings,
  onHandleModal: (props: ModalContext) => void
): DealInteractionDropdownSettings => {
  const disableDropdown = () => {
    return !!(
      dealSettings.dealDataErrors && dealSettings.dealDataErrors.length
    );
  };
  const getProposalLabel = () => {
    return dealSettings.dealExchangeId ? "Launch Deal" : "Create New Deal";
  };
  const enabled = !disableDropdown();
  if (!enabled)
    return {
      enabled
    } as DealInteractionDropdownSettings;
  return {
    enabled,
    copyClick: () => {
      onHandleModal({
        url: `${dealSettings.digitalRetailWfeLink}&display=copylink`,
        showModal: true
      });
    },
    proposalClick: () => {
      onHandleModal({
        url: `${dealSettings.digitalRetailWfeLink}&display=amdcmd`,
        showModal: true
      });
    },
    proposalLabel: getProposalLabel()
  } as DealInteractionDropdownSettings;
};

export const buildCopyLinkObject = (
  dealSettings: DealSettings,
  onHandleModal: (props: ModalContext) => void
): CopyLinkSettings => {
  const disableButton = () => {
    return !!(
      dealSettings.dealDataErrors && dealSettings.dealDataErrors.length
    );
  };

  const enabled = !disableButton();
  if (!enabled)
    return {
      enabled
    } as CopyLinkSettings;
  return {
    enabled,
    click: () => {
      if (dealSettings && dealSettings.digitalRetailWfeLink) {
        onHandleModal({
          showModal: true,
          url: `${dealSettings.digitalRetailWfeLink}&display=copylink`
        });
      }
    }
  } as CopyLinkSettings;
};
