import { useCallback, useEffect, useState } from "react";
import { ModalContext } from "./interfaces/modal-context";
import {
  DealSettings,
  getCustomerDashboardSettings
} from "@vinsolutions/data-access/workflow/crm.workflow.deal.api";
import { useReactOidc } from "@axa-fr/react-oidc-context";
import { DealInteraction } from "./components/deal-interaction";
import DealInteractionModal from "./components/deal-interactionl-modal";
import { DealInteractionErrors } from "./components/deal-interaction-errors";
import { DealInteractionCopyLink } from "./components/deal-interaction-copy-link";
import { LeadDealInteractionProps } from "./interfaces/lead-deal-interaction-props";

const defaultModalContext: ModalContext = {
  url: "",
  showModal: false
};

export const LeadDealInteraction = ({
  dealerId,
  leadId,
  viewVdpConfig
}: LeadDealInteractionProps) => {
  const [modalContext, setModalContext] = useState(defaultModalContext);
  const jwt = useReactOidc().oidcUser.access_token;
  const [dealSettings, setDealSettings] = useState<
    DealSettings | null | undefined
  >(null);
  const handleModal = (modalContext: ModalContext) => {
    setModalContext(modalContext);
  };

  const loadDealSettings = useCallback(async () => {
    const dealSettingsResult = await getCustomerDashboardSettings(
      dealerId,
      leadId,
      viewVdpConfig && viewVdpConfig.visible ? viewVdpConfig.href : null,
      jwt
    );
    setDealSettings(dealSettingsResult?.data);
  }, [dealerId, jwt, leadId, viewVdpConfig]);

  useEffect(() => {
    if (leadId && dealerId) {
      loadDealSettings();
    }
  }, [leadId, dealerId, loadDealSettings]);
  return (
    <>
      {dealSettings &&
        (dealSettings?.dealDataErrors === null ||
          dealSettings?.dealDataErrors?.length <= 0) && (
          <>
            <DealInteraction
              dealSettings={dealSettings}
              onHandleModal={handleModal}
            />
            <DealInteractionCopyLink
              dealSettings={dealSettings}
              onHandleModal={handleModal}
            />
          </>
        )}

      <DealInteractionErrors
        dealDataErrors={dealSettings?.dealDataErrors || null}
      />
      <DealInteractionModal
        showModal={modalContext.showModal}
        url={modalContext.url}
        onHide={() => setModalContext(defaultModalContext)}
      />
    </>
  );
};

export default LeadDealInteraction;
