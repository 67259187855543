import { useState } from "react";
import { ViewPhotosProps } from "../interfaces/view-photos-props";
import { Button } from "@vinsolutions/core-cx";
import styled from "styled-components";
import VehicleInteractionModal from "./vehicle-interaction-modal";
import { ModalContext } from "../interfaces/modal-context";
const defaultModalContext: ModalContext = {
  url: "",
  showModal: false
};

const StyledButton = styled(Button)`
  margin-right: 5px;
  margin-bottom: 5px;
`;

export const ViewPhotos = ({ viewPhotosConfig }: ViewPhotosProps) => {
  const [modalContext, setModalContext] = useState(defaultModalContext);
  return viewPhotosConfig.visible && viewPhotosConfig.href ? (
    <>
      <StyledButton
        buttonStyle="secondary"
        htmlId="vehiclePhotosLink"
        onClick={() =>
          setModalContext({
            url: viewPhotosConfig.href ?? "",
            showModal: true
          })
        }
      >
        {viewPhotosConfig.text}
      </StyledButton>
      <VehicleInteractionModal
        showModal={modalContext.showModal}
        url={modalContext.url}
        onHide={() => setModalContext(defaultModalContext)}
      />
    </>
  ) : null;
};
