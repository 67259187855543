// eslint-disable-next-line @nx/enforce-module-boundaries
import { environments, getEnvironmentName } from "@vinsolutions/core/config";
import { fetchWrapper } from "@vinsolutions/core-http";
import { createLogger } from "@vinsolutions/logger";

const logger = createLogger("vin.api.email");

function getBaseUrl() {
  const env = getEnvironmentName();
  switch (env) {
    case environments.local:
    case environments.localdev:
      return "https://dev-communications.vinsolutions.com";
    case environments.qaCoxAutoDomain:
    case environments.qaOnPrem:
      return "https://qa-communications.vinsolutions.com";
    case environments.staging:
    case environments.stagingCoxAutoDomain:
      return "https://staging-communications.vinsolutions.com";
    case environments.prod:
    case environments.prodCoxAutoDomain:
      return "https://communications.vinsolutions.com";
    default:
      return "https://qa-communications.vinsolutions.com";
  }
}

async function getHeaders() {
  return undefined;
}

export async function getDomainSetupStatus(
  dealerId: number,
  jwt: string
): Promise<DomainMailStatus[] | null | undefined> {
  if (dealerId > 0) {
    const baseUrl = getBaseUrl();

    const urlToCall =
      "/vin.api.email/api/sendgrid/dealer/" + dealerId + "/domain-check/bff";

    logger.info("Calling " + baseUrl + urlToCall);
    const response = await fetchWrapper.getAsync<DomainMailStatus[]>(
      baseUrl + urlToCall,
      {
        Authorization: "Bearer " + jwt
      }
    );

    if (response.statusCode !== 200) {
      logger.error(
        "Error getting domain setup status",
        JSON.stringify(response)
      );
      return [];
    }

    logger.debug("Domain setup status", JSON.stringify(response));
    return response.data;
  }

  throw new Error("Invalid dealerId");
}

export async function validateDomainSetup(
  dealerId: number,
  domainId: string,
  jwt: string
): Promise<DomainValidationResult | null | undefined> {
  if (!dealerId) {
    throw new Error("Invalid dealerId");
  }
  if (!domainId) {
    throw new Error("Invalid domainId");
  }
  const baseUrl = getBaseUrl();

  const urlToCall =
    "/vin.api.email/api/sendgrid/dealer/" +
    dealerId +
    "/domain/" +
    domainId +
    "/validate";
  const response = await fetchWrapper.postAsync<DomainValidationResult>(
    baseUrl + urlToCall,
    null,
    {
      Authorization: "Bearer " + jwt
    }
  );

  if (response.statusCode !== 200) {
    logger.error("Error getting domain setup status", JSON.stringify(response));
    return null;
  }

  logger.debug("Domain setup status", JSON.stringify(response));
  return response.data;
}

export interface DomainValidationResult {
  id: string;
  valid: boolean;
  // eslint-disable-next-line camelcase
  validation_results: ValidationResult;
}

export interface ValidationResult {
  // eslint-disable-next-line camelcase
  mail_cname: ValidationResultDetail;
  dkim1: ValidationResultDetail;
  dkim2: ValidationResultDetail;
}

export interface ValidationResultDetail {
  valid: boolean;
  reason: string;
}

export interface DomainMailStatus {
  dealerId: number;
  domain: string;
  domainId: string;
  validationResults: DomainSavedValidationResult[];
}

export interface DomainSavedValidationResult {
  type: string;
  isValid: boolean;
  host: string;
  data: string;
  fix: string;
  configuredValueDNS: string;
}
