import { AddVehicleAcquisitionPage } from "@vinsolutions/page/add-vehicle-acquisition";
import { RouteBuilder } from "../route-builder";

/**
 * For more details on how to create pages, see the documentation:
 * [Create Page Guide](../../../../../../../docs/createPage.md)
 *
 * To view the rendered page, visit:
 * https://qa-vinsolutions.app.coxautoinc.com/vinconnect/pane-full/vinconnect-add-vehicle-acquisition
 */
export const addVehicleAcquisitionRoute = RouteBuilder.create()
  .withRouteId("vinconnect", "add-vehicle-acquisition")
  .withAliasId("navigation-sub-menu-tab-crm-add-customer-vehicle-acquisition")
  .withFullComponent(<AddVehicleAcquisitionPage />)
  .build();
