import {
  PortalMessage,
  RegisteredPortal,
  RegisteredPortalFactory
} from "@vinsolutions/utility/portals";
import { PortalEventData } from "../interfaces/portal-event-data";
import LeadVehicleActionsPortalInterface from "./lead-vehicle-actions-portal-interface";
import { LeadVehicleActionsPortalInterfaceProps } from "../interfaces/lead-vehicle-actions-portal-interface-props";

export class LeadVehicleActionsPortalFactory
  implements RegisteredPortalFactory<LeadVehicleActionsPortalInterfaceProps>
{
  create({
    originDocument,
    originElement,
    portalId,
    portalKey,
    portalProps
  }: PortalMessage): RegisteredPortal<LeadVehicleActionsPortalInterfaceProps> {
    const dataIsland = (portalProps?.data || {}) as PortalEventData;
    const actionsProps = {
      ...(portalProps || {}),
      customerId: dataIsland?.customerId,
      dealerId: dataIsland?.dealerId ?? 0,
      leadId: dataIsland?.autoLeadId ?? 0,
      portalId,
      viewPhotosConfig: dataIsland.viewPhotosConfig,
      viewVdpConfig: dataIsland.viewVdpConfig
    } as LeadVehicleActionsPortalInterfaceProps;

    return {
      originDocument,
      originElement,
      portalId,
      portalKey,
      portalComponent: LeadVehicleActionsPortalInterface,
      portalComponentProps: actionsProps,
      isHidden: true // visibility toggled on by nx.vinconnect.portal.lead-deal-interaction.deal
    };
  }
}
