export interface Item {
  label: string;
  items: Item[];
  url: string;
  target: string;
}

const url = "/CarDashboard/MenuOnly.aspx";

export async function getMenu(): Promise<Item[]> {
  const request = await fetch(url);
  return request.json();
}
