import { Table } from "@interstate/components/Table";
import { PlusIcon } from "@interstate/components/Icons";
import { EnrichedPermission } from "../../types/authSettings.type";
import { PermissionsTableColumnsConfig } from "./authSettingsPermissions.config";
import { AuthSettingsPermissionsActionButton } from "./authSettingsPermissionsActionButton.component";
import { generateId } from "../../helpers";

export const AuthSettingsPermissions = ({
  permissions,
  parentId
}: {
  permissions: EnrichedPermission[];
  parentId: string;
}) => {
  const defaultPermission = {
    action: "AddConductorLead",
    allowed: true,
    key: generateId(),
    parentId
  };

  return (
    <>
      <AuthSettingsPermissionsActionButton
        buttonIcon={<PlusIcon />}
        buttonLabel="Add Permission"
        initialPermission={defaultPermission}
      />
      <Table
        enablePagination
        columns={PermissionsTableColumnsConfig}
        data={permissions}
        defaultPageSize={10}
        emptyText="Not permissions found."
        headerBackgroundColor="light"
        id="oem-auth-settings-permissions-table"
        sortableDetails={{
          activeSortColumn: "action",
          sortOrder: "ascend",
          sortableColumns: ["action"]
        }}
      />
    </>
  );
};
