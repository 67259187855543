import { getVinManagerInventoryMaster } from "./vinmanager-inventory-master";
import {
  NavigationEntity,
  NavigationItemsEntity
} from "@vinsolutions/ccrm/interfaces";
import { buildEmailNavigationItems } from "./build-email-navigation";
import { buildCallNavigationItems } from "./build-call-navigation";
import { buildFacebookNavigationItems } from "./build-facebook-navigation";
import { buildTextNavigationItems } from "./build-text-navigation";
import { communicationsNavigationItemEntityIds } from "./navigation-ids";
import { buildMessagingNavigationItems } from "./build-messaging-navigation";

import { createLogger } from "vinsolutions/logger";

const logger = createLogger("build-facebook-navigation");

export const getCommunicationsNavigationItemEntities = () => {
  logger.info("getCommunicationsNavigationItemEntities");
  const vinManagerInventoryMaster = getVinManagerInventoryMaster();

  if (!vinManagerInventoryMaster) {
    logger.info("VinManagerInventoryMaster is not available");
    return {
      communicationsItemEntities: [] as NavigationItemsEntity[],
      communicationsTabNavigationEntity: null
    };
  }

  const { emailNavigationItemEntities, emailNavigationEntity } =
    buildEmailNavigationItems(vinManagerInventoryMaster);

  const { textNavigationItemEntities, textNavigationEntity } =
    buildTextNavigationItems(vinManagerInventoryMaster);

  const { callNavigationItemEntities, callNavigationEntity } =
    buildCallNavigationItems(vinManagerInventoryMaster);

  const { messagingNavigationItemEntities, messagingNavigationEntity } =
    buildMessagingNavigationItems(vinManagerInventoryMaster);

  // Email, Text, and Call are all similar. Facebook is an outlier in the sense that it actually will return multiple NavigationEntity.
  const { facebookNavigationItemEntities, facebookNavigationEntities } =
    buildFacebookNavigationItems(vinManagerInventoryMaster);

  const communicationsItemEntities: NavigationItemsEntity[] = [
    ...emailNavigationItemEntities,
    ...textNavigationItemEntities,
    ...callNavigationItemEntities,
    ...messagingNavigationItemEntities,
    ...facebookNavigationItemEntities
  ];

  const communicationsNavigationEntities: NavigationEntity[] = [];

  if (emailNavigationEntity !== null) {
    communicationsNavigationEntities.push(emailNavigationEntity);
  }

  if (textNavigationEntity !== null) {
    communicationsNavigationEntities.push(textNavigationEntity);
  }

  if (callNavigationEntity !== null) {
    communicationsNavigationEntities.push(callNavigationEntity);
  }

  if (messagingNavigationEntity !== null) {
    communicationsNavigationEntities.push(messagingNavigationEntity);
  }

  if (facebookNavigationEntities !== null) {
    communicationsNavigationEntities.push(...facebookNavigationEntities);
  }

  const communicationsTabNavigationEntity: NavigationEntity = {
    id: communicationsNavigationItemEntityIds.communicationsTab,
    items: communicationsNavigationEntities
  };

  convertCommunicationsEntitiesToItemsEntities(communicationsItemEntities, [
    ...communicationsNavigationEntities,
    communicationsTabNavigationEntity
  ]);

  return { communicationsItemEntities, communicationsTabNavigationEntity };
};

const convertCommunicationsEntitiesToItemsEntities = (
  communicationsItemsEntities: NavigationItemsEntity[],
  communicationsNavigationEntities: NavigationEntity[]
) => {
  logger.info("convertCommunicationsEntitiesToItemsEntities");
  communicationsNavigationEntities.forEach(entity => {
    if (
      communicationsItemsEntities.findIndex(
        existing => existing.id === entity.id
      ) === -1
    ) {
      communicationsItemsEntities.push({
        id: entity.id,
        label: getCommunicationsLabelFromId(entity.id),
        url: "#",
        target: "_self",
        type: "COMMUNICATIONS"
      });
    }
  });
};

const getCommunicationsLabelFromId = (id: string) => {
  let label = "";

  switch (id) {
    case communicationsNavigationItemEntityIds.calls:
      label = "Calls";
      break;
    case communicationsNavigationItemEntityIds.email:
      label = "Email";
      break;
    case communicationsNavigationItemEntityIds.facebookLog:
      label = "Facebook Log";
      break;
    case communicationsNavigationItemEntityIds.facebookMessenger:
      label = "Facebook Messenger";
      break;
    case communicationsNavigationItemEntityIds.text:
      label = "Text";
      break;
    case communicationsNavigationItemEntityIds.messageProviders:
      label = "Message Providers";
      break;
    case communicationsNavigationItemEntityIds.communicationsTab:
      label = "Communications";
      break;
    default:
      break;
  }
  logger.info(`getCommunicationsLabelFromId: ${label}`);
  return label;
};
