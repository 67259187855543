import { LeadVehicleActionsProps } from "../interfaces/lead-vehicle-actions-props";
import { LeadDealInteraction } from "@vinsolutions/sub-feature/deal-interaction";
import styled from "styled-components";
import {
  ViewPhotos,
  ViewVdp
} from "@vinsolutions/sub-feature/vehicle-interaction";

export type LeadVehicleActionsCollectionProps = LeadVehicleActionsProps;

const StyledActionsCollection = styled.div`
  display: flex;
`;

export const ActionsCollection = ({
  dealerId,
  leadId,
  viewPhotosConfig,
  viewVdpConfig
}: LeadVehicleActionsCollectionProps) => {
  return (
    <StyledActionsCollection>
      {viewPhotosConfig && <ViewPhotos viewPhotosConfig={viewPhotosConfig} />}
      {viewVdpConfig && (
        <ViewVdp
          href={viewVdpConfig.href}
          text={viewVdpConfig.text}
          visible={viewVdpConfig.visible}
        />
      )}
      <LeadDealInteraction
        dealerId={dealerId}
        leadId={leadId}
        viewVdpConfig={viewVdpConfig}
      />
    </StyledActionsCollection>
  );
};

export default ActionsCollection;
