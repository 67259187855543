import DealInteractionIconSmall from "./deal-interaction-icon-small";
import useCopyLink from "../hooks/useCopyLink";
import { DealInteractionProps } from "../interfaces/deal-interaction-props";
import styled from "styled-components";
import { Button } from "@vinsolutions/core-cx";

const DealInteractionCopyLinkStyledButton = styled(Button)`
  margin-right: 5px;
  margin-bottom: 5px;
  display: flex;
  height: 32px;
  .accelerate-icon {
    padding-bottom: 6px;
  }
`;

export const DealInteractionCopyLink = (props: DealInteractionProps) => {
  const copyLinkSettings = useCopyLink(props);
  return copyLinkSettings ? (
    <DealInteractionCopyLinkStyledButton
      buttonStyle="secondary"
      disabled={!copyLinkSettings.enabled}
      htmlId="deal-interactionCopyLink"
      onClick={copyLinkSettings.click}
    >
      <DealInteractionIconSmall />
      Copy Link
    </DealInteractionCopyLinkStyledButton>
  ) : null;
};
