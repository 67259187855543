import { IVinManagerInventoryMaster } from "./vinmanager-inventory-master";
import { communicationsNavigationItemEntityIds } from "./navigation-ids";
import { generateDestinationUrl } from "./vinmanager-inventory-master";
import {
  NavigationEntity,
  NavigationItemsEntity
} from "@vinsolutions/ccrm/interfaces";
import { createLogger } from "vinsolutions/logger";

const logger = createLogger("build-messaging-navigation");

const getAllMessagingNavigationItems = (
  vinManagerInventoryMaster: IVinManagerInventoryMaster
) => {
  logger.info("getAllMessagingNavigationItems");
  const messagingNavigationItemsEntities: NavigationItemsEntity[] = [
    {
      id: communicationsNavigationItemEntityIds.messageProviders,
      label: "Message Providers",
      url: generateDestinationUrl(
        {
          leftPage: "/CarDashboard/Pages/rims2.aspx",
          leftQueryString: {
            urlSettingName: "brawndo.communicationsui.url",
            type: "third party providers",
            mp: true
          }
        },
        vinManagerInventoryMaster
      ),
      target: "_self",
      type: "COMMUNICATIONS"
    }
  ];

  return messagingNavigationItemsEntities;
};

export const buildMessagingNavigationItems = (
  vinManagerInventoryMaster: IVinManagerInventoryMaster
) => {
  logger.info("buildMessagingNavigationItems");
  if (!vinManagerInventoryMaster.IsDealerEnabledForThirdPartyMessaging) {
    logger.info("Dealer is not enabled for third party messaging");
    return {
      messagingNavigationItemEntities: [],
      messagingNavigationEntity: null
    };
  }

  const messagingNavigationItemEntities = getAllMessagingNavigationItems(
    vinManagerInventoryMaster
  );

  const messagingNavigationEntity: NavigationEntity = {
    id: communicationsNavigationItemEntityIds.messageProviders
  };

  return {
    messagingNavigationItemEntities,
    messagingNavigationEntity
  };
};
