/* eslint-disable @cspell/spellchecker */
import { useReactOidc } from "@axa-fr/react-oidc-context";
import {
  DealApplication,
  DealSettingsResponse
} from "@vinsolutions/data-access/workflow/crm.workflow.deal.api";
import { ToastContainer, toast } from "cx";
import { useMemo } from "react";
import styled from "styled-components";
import { getDealApplicationEvent } from "../../events/deal-application-event";
import { DealDetailsModalProps } from "../../interfaces/deal-details-modal-props";
import { ApplicationButtonProps } from "../../interfaces/deal-details-modal/application-button-props";
import GlobalStyle from "../../styles/global-style";
import ApplicationLabel from "./application-label";
import ApplicationSelector from "./application-selector";
import ApplicationTextIcon from "./application-text-icon";
import ContentFrame from "./content-frame";

const IFRAME_TITLE = "accelerate";
const IFRAME_ID = "accelerateUrlnew";

const StyledDealDetailsModal = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledToastContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const onApplicationError = (
  errorTitle: string | undefined,
  errorMessage: string | undefined
): void => {
  if (errorMessage)
    toast.error(
      <StyledToastContent>
        <b>{errorTitle}</b>
        {errorMessage}
      </StyledToastContent>
    );
};

const getApplicationButtonProps = (
  dealApplication: DealApplication,
  dealSettings: DealSettingsResponse,
  leadId: number | undefined,
  jwt: string
): ApplicationButtonProps => {
  const applicationButtonProps: ApplicationButtonProps = {
    htmlId: dealApplication.id,
    label: (
      <ApplicationLabel
        icon=<ApplicationTextIcon text={dealApplication.displayIconText} />
        labelText={dealApplication.displayLabelText}
      />
    ),
    name: dealApplication.name,
    onClick: () => {
      const event = getDealApplicationEvent(dealApplication.eventType);
      if (event) {
        event({
          applicationName: dealApplication.name,
          applicationUrl: dealApplication.launchUrl,
          dealExchangeDealId: dealSettings.dealExchangeDealId,
          dealExchangeVersionId: dealSettings.dealExchangeVersionId,
          leadId,
          jwt,
          onApplicationError
        });
      }
    }
  };

  return applicationButtonProps;
};

export function DealDetailsModal({
  dealSettings,
  dealSummary,
  leadId
}: DealDetailsModalProps) {
  const jwt = useReactOidc().oidcUser.access_token;

  const applications = useMemo(() => {
    return dealSummary && dealSummary?.dealApplications
      ? dealSummary.dealApplications.map(x =>
          getApplicationButtonProps(x, dealSettings, leadId, jwt)
        )
      : [];
  }, [dealSettings, dealSummary, leadId, jwt]);

  return (
    <>
      <GlobalStyle />
      <StyledDealDetailsModal>
        <ToastContainer />
        <ContentFrame
          iFrameId={IFRAME_ID}
          iFrameTitle={IFRAME_TITLE}
          iFrameUrl={dealSummary?.dealDetailsUrl || ""}
        />
        <ApplicationSelector applications={applications} />
      </StyledDealDetailsModal>
    </>
  );
}
