import { TopPortalStyleProvider } from "@vinsolutions/utility/portals";
import { ModalDialog } from "@vinsolutions/core-cx";
import styled from "styled-components";

const StyledVehicleInteractionModalContent = styled.iframe`
  width: 100%;
  height: 100%;
  border: 0px;
`;

const StyledModalDialog = styled(ModalDialog)<ModalProps>`
  padding-top: 170px;
  .modal-dialog.modal-dialog {
    width: ${props => props.width}px;
    .modal-content.modal-content {
      .modal-header {
        padding: 8px 16px 0 16px;
        .close {
          margin-top: 2px;
        }
      }
      .modal-body {
        height: ${props => props.height}px;
      }
    }
  }
`;
export interface DealModalProps {
  url: string;
  showModal: boolean;
  onHide: () => void;
}
interface ModalProps {
  height: number;
  width: number;
}

const VehicleInteractionModal = ({
  url,
  showModal,
  onHide
}: DealModalProps) => {
  return (
    <TopPortalStyleProvider cacheKey="vehicle-interaction-modal">
      <StyledModalDialog
        data-testid="vehicle-interaction-modal-dialog"
        height={window.innerHeight * 0.7}
        htmlId="vehicle-interaction-modal-dialog"
        show={showModal}
        width={window.innerWidth * 0.6}
        onHide={onHide}
      >
        <StyledVehicleInteractionModalContent
          id="vehicle-interaction-iframe-id"
          src={url}
        />
      </StyledModalDialog>
    </TopPortalStyleProvider>
  );
};

export default VehicleInteractionModal;
