import { OverlayEventChannels } from "./overlay-event-channels";

/**
 * Event channels for VinConnect overlays.
 *
 * @type {OverlayEventChannels}
 */
export const VinConnectOverlayEventChannels: OverlayEventChannels = {
  openOverlay: "vinconnect:overlay:open",
  closeOverlay: "vinconnect:overlay:close",
  hideOverlays: "vinconnect:overlay:hideAll",
  showOverlays: "vinconnect:overlay:showAll",
  switchOverlayPosition: "vinconnect:overlay:switchPosition",
  toggleOverlaySize: "vinconnect:overlay:toggleSize"
};
