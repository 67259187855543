import { useReactOidc } from "@axa-fr/react-oidc-context";
import { getProfileState } from "@vinsolutions/ccrm/store";
import {
  UnifiedInboxDefaultTab,
  UnifiedInboxModule
} from "@vinsolutions/sub-feature/unified-inbox";
import React, { useCallback, useMemo } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { trackInNewRelic } from "@vinsolutions/core-third-party-newrelic";
import { environments, getEnvironmentName } from "@vinsolutions/core/config";
import styled from "styled-components";

const StyledCustomerInbox = styled.div`
  display: grid;
  height: 100%;
  width: 100%;
  grid-template-columns: 1fr;
  grid-template-rows: min-content 1fr;
  grid-template-areas:
    "header"
    "module";

  .customer-inbox-title {
    grid-area: header;
    margin: 16px 16px 8px 16px;
  }

  .customer-inbox-module-wrapper {
    position: relative;
    margin: 8px 16px 16px;
    grid-area: module;
  }
`;

export interface CustomerInboxProps {
  autoLeadId: string;
  customerId: string;
  defaultTab: UnifiedInboxDefaultTab;
}

const unifiedInboxSupportedEnvironments = [
  environments.qaCDN,
  environments.qaCDNImpersonation,
  environments.qaCoxAutoDomain,
  environments.qaOnPrem,
  environments.prod,
  environments.prodCoxAutoDomain
];

const unifiedInboxProdEnvironments = [
  environments.prod,
  environments.prodCoxAutoDomain
];

export function CustomerInbox({
  autoLeadId,
  customerId,
  defaultTab
}: CustomerInboxProps) {
  const { oidcUser } = useReactOidc();
  const { isVinEmployee } = useSelector(getProfileState, shallowEqual);

  const getJwtOverride = useCallback(() => {
    return Promise.resolve(oidcUser.access_token);
  }, [oidcUser.access_token]);

  const errorCallback = useCallback((errorMessage: string) => {
    trackInNewRelic({
      componentAction: "UnifiedInboxInTheCRM",
      locationLoadedFrom: "unified-inbox-overlay",
      errorContext: errorMessage
    });
  }, []);

  const isUnifiedInboxSupportedEnvironment = useMemo(() => {
    return unifiedInboxSupportedEnvironments.includes(getEnvironmentName());
  }, []);

  const environment = useMemo(
    () =>
      unifiedInboxProdEnvironments.includes(getEnvironmentName())
        ? "prod"
        : "nonprod",
    []
  );

  return isUnifiedInboxSupportedEnvironment ? (
    <StyledCustomerInbox>
      <h3 className="customer-inbox-title">Customer Inbox</h3>
      <div className="customer-inbox-module-wrapper">
        <UnifiedInboxModule
          consumerId={customerId}
          customerSystem={"VIN"}
          defaultTab={defaultTab}
          disableReply={isVinEmployee}
          environment={environment}
          errorCallback={errorCallback}
          getJwtOverride={getJwtOverride}
          selectedLeadId={autoLeadId}
        />
      </div>
    </StyledCustomerInbox>
  ) : (
    <div>
      Unfortunately, this feature is unavailable in the CRM Development and
      Staging environments.
    </div>
  );
}

export default CustomerInbox;
