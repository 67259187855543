import styled from "styled-components";
import { ViewVdpProps } from "../interfaces/view-vdp-props";
import { Button } from "@vinsolutions/core-cx";

const StyledButton = styled(Button)`
  margin-right: 5px;
  margin-bottom: 5px;
`;

export const ViewVdp = (viewVdp?: ViewVdpProps) => {
  const handleClick = (url: string) => {
    window.open(url, "_blank", "noopener noreferrer");
  };

  return viewVdp && viewVdp.visible && viewVdp.href !== null ? (
    <StyledButton
      buttonStyle="secondary"
      htmlId="vehicleVdpLink"
      onClick={() => handleClick(viewVdp.href ?? "")}
    >
      {viewVdp.text}
    </StyledButton>
  ) : null;
};
