import { useCallback, useEffect, useState } from "react";
import { FeatureMarkLeadStatusProps } from "./interfaces/mark-lead-status-props";
import {
  getReasons,
  setMappedResults
} from "@vinsolutions/data-access/lead/crm.lead.bff";
import {
  ComboBox,
  ComboBoxEventValue,
  ComboBoxOptions
} from "@interstate/components/ComboBox";
import { useReactOidc } from "@axa-fr/react-oidc-context";
import { InterstateOnChangeEvent } from "@interstate/components/InterstateEvents";
import { Box } from "@interstate/components/Box";
import { TextArea } from "@interstate/components/TextArea";
import { Button } from "@interstate/components/Button";

function getValue(
  options: ComboBoxOptions,
  selectedValue: string | number
): ComboBoxOptions | undefined {
  const selectedOption = options.find(
    s => s.value === selectedValue.toString()
  );

  return selectedOption ? [selectedOption] : undefined;
}

export function FeatureMarkLeadStatus({
  assignedLeadType,
  dealerId,
  onCancel,
  onClose
}: FeatureMarkLeadStatusProps) {
  const [reasons, setReasons] = useState<ComboBoxOptions>([]);
  const [reason, setReason] = useState<number>(0);
  const [notes, setNotes] = useState<string>("");

  const oidcUser = useReactOidc().oidcUser;

  const loadDropdownData = useCallback(async () => {
    const result =
      oidcUser && dealerId > 0
        ? await getReasons(
            assignedLeadType,
            dealerId,
            oidcUser.access_token,
            true
          )
        : null;

    if (result) {
      setMappedResults(setReasons, result);
    }
  }, [assignedLeadType, dealerId, oidcUser]);

  useEffect(() => {
    loadDropdownData();
  }, [loadDropdownData]);

  function onReasonChange(event: InterstateOnChangeEvent<ComboBoxEventValue>) {
    if (
      !event.isValid ||
      !event.target.value ||
      !Array.isArray(event.target.value) ||
      event.target.value.length === 0 ||
      !event.target.value[0].value
    ) {
      return;
    }

    const id = reasons.find(
      obj => obj.value === event.target.value[0].value
    )?.value;

    const value = id ? +id : undefined;

    if (value) {
      setReason(value);
    }
  }

  return (
    <Box>
      <ComboBox
        label="Reason"
        name="reason-input"
        options={reasons}
        required={true}
        size="small"
        value={getValue(reasons, reason)}
        onChange={event => onReasonChange(event)}
      />

      <TextArea
        label="Notes (optional)"
        name="notes-input"
        rows={3}
        size="small"
        value={notes}
        onChange={event => setNotes(event.target.value)}
      />
      <Box
        columnGap="1.5em"
        display="flex"
        justifyContent={"flex-end"}
        paddingTop={"1em"}
      >
        <Button
          buttonStyle="tertiary"
          data-testid="button-cancel"
          id="cancel-button"
          size="medium"
          onClick={onCancel}
        >
          Cancel
        </Button>

        <Button
          buttonStyle="primary"
          data-testid="button-save"
          id="save-button"
          size="medium"
          onClick={() => {
            // TODO: Save

            onClose();
          }}
        >
          Save
        </Button>
      </Box>
    </Box>
  );
}

export default FeatureMarkLeadStatus;
