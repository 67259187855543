import { ActionsCollection } from "./components/actions-collection";
import { LeadVehicleActionsProps } from "./interfaces/lead-vehicle-actions-props";

export const LeadVehicleActions = ({
  dealerId,
  leadId,
  viewPhotosConfig,
  customerId,
  viewVdpConfig
}: LeadVehicleActionsProps) => {
  return (
    <ActionsCollection
      customerId={customerId}
      dealerId={dealerId}
      leadId={leadId}
      viewPhotosConfig={viewPhotosConfig}
      viewVdpConfig={viewVdpConfig}
    />
  );
};

export default LeadVehicleActions;
