import { usePortalsContext } from "@vinsolutions/utility/portals";
import LeadVehicleActions from "../lead-vehicle-actions";
import { useEffect } from "react";
import { useVinconnectFlagEnabled } from "@vinsolutions/core-third-party-launch-darkly";
import { LeadVehicleActionsPortalInterfaceProps } from "../interfaces/lead-vehicle-actions-portal-interface-props";

const LeadVehicleActionsPortalInterface = ({
  dealerId,
  portalId,
  leadId,
  customerId,
  viewPhotosConfig,
  viewVdpConfig,
  portalEnabledCallback
}: LeadVehicleActionsPortalInterfaceProps) => {
  const { setPortalVisibility } = usePortalsContext();
  const portalFlagEnabled = useVinconnectFlagEnabled(
    "nx.vinconnect.portal.lead-deal-interaction.deal"
  );
  useEffect(() => {
    setPortalVisibility(portalId, portalFlagEnabled);
    if (portalEnabledCallback) {
      portalEnabledCallback(portalFlagEnabled);
    }
  }, [portalFlagEnabled, portalId, portalEnabledCallback, setPortalVisibility]);
  return (
    portalFlagEnabled === true && (
      <LeadVehicleActions
        customerId={customerId}
        dealerId={dealerId}
        leadId={leadId}
        viewPhotosConfig={viewPhotosConfig}
        viewVdpConfig={viewVdpConfig}
      />
    )
  );
};

export default LeadVehicleActionsPortalInterface;
