import { RouteComponent } from "@vinsolutions/ccrm/top-nav/route-config";
import { createLogger } from "@vinsolutions/logger";

const logger = createLogger("build-cardashboard-url");

export type CdUrlType = "active-lead" | "dynamic-frame";
/**
 * Builds the URL for cardashboard components
 * It builds the URL based on the ActiveLeadsLayout.aspx component
 * @param {RouteComponent}  leftComponent   The component for the left side
 * @param {RouteComponent}  rightComponent  The component for the right side
 * @param {RouteComponent}  fullComponent   The component for the fill width, will default to the left side
 * @param {CdUrlType}       cdUrlType       The type of URL for the cardashboard view.
 */

export const buildCarDashboardUrl = (
  leftComponent: RouteComponent,
  rightComponent: RouteComponent,
  fullComponent: RouteComponent,
  cdUrlType: CdUrlType
) => {
  logger.debug(
    "buildCarDashboardUrl: " +
      cdUrlType +
      " " +
      leftComponent +
      " " +
      rightComponent +
      " " +
      fullComponent
  );
  let left = "HIDE";
  let right = "HIDE";
  if (fullComponent) {
    if (typeof fullComponent === "string") {
      left = fullComponent;
    } else {
      return undefined;
    }
  } else if (
    leftComponent &&
    typeof leftComponent === "string" &&
    rightComponent &&
    typeof rightComponent === "string"
  ) {
    left = leftComponent;
    right = rightComponent;
  } else if (leftComponent && typeof leftComponent === "string") {
    left = leftComponent;
  } else if (rightComponent && typeof rightComponent === "string") {
    right = rightComponent;
  } else {
    return undefined;
  }
  switch (cdUrlType) {
    case "active-lead": {
      logger.debug("buildCarDashboardUrl: loading ActiveLeadsLayout.aspx");
      return `/CarDashboard/Pages/LeadManagement/ActiveLeadsLayout.aspx?NoMenu=true&SelectedTab=t_ILM&leftpaneframe=${left}&rightpaneframe=${right}`;
    }
    case "dynamic-frame": {
      logger.debug("buildCarDashboardUrl: loading dynamicframe.aspx");
      return `/CarDashboard/Pages/dynamicframe.aspx?leftpaneframe=${left}&rightpaneframe=${right}`;
    }
  }
};
