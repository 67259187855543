import { useVinconnectFlagEnabled } from "@vinsolutions/core/third-party/launch-darkly";
import {
  PortalComponentProps,
  usePortalsContext
} from "@vinsolutions/utility/portals";
import { useEffect } from "react";
import CustomerDashboardCommunicationsIcon from "./customer-dashboard-communications-icon";
import styled from "styled-components";

export interface CustomerDashboardCommunicationsButtonProps
  extends PortalComponentProps {
  autoLeadId?: string;
  customerId?: string | number;
}

export interface UnifiedInboxOverlayEvent {
  action: "Open" | "Close";
  data?: UnifiedInboxOverlayEventPayload;
}

export interface UnifiedInboxOverlayEventPayload {
  autoLeadId?: string;
  customerId: string;
  defaultTab?: UnifiedInboxDefaultTab;
  overlayPosition: UnifiedInboxOverlayPosition;
}

// this should be defined where the overlay is
// but because the overlay event can't be defined with the overlay
// all the payloads will have to be in a single "eventing" library. How fun!
export enum UnifiedInboxDefaultTab {
  all = "all",
  email = "email",
  phone = "phone",
  text = "text"
}

// this should be defined where the overlay is
// but because the overlay event can't be defined with the overlay
// all the payloads will have to be in a single "eventing" library. How fun!
export enum UnifiedInboxOverlayPosition {
  LEFT = "left",
  RIGHT = "right"
}

const StyledClickableIconWrapper = styled.div`
  width: min-content;

  &:hover {
    cursor: pointer;
  }
`;

const CustomerDashboardCommunicationsButton = ({
  autoLeadId = "",
  customerId = "",
  portalId,
  portalEnabledCallback
}: CustomerDashboardCommunicationsButtonProps) => {
  const { setPortalVisibility } = usePortalsContext();
  const portalFlagEnabled = useVinconnectFlagEnabled(
    "nx.vinconnect.show-comms-inbox"
  );

  useEffect(() => {
    setPortalVisibility(portalId, portalFlagEnabled);
    if (portalEnabledCallback) {
      portalEnabledCallback(portalFlagEnabled);
    }
  }, [portalFlagEnabled, portalId, portalEnabledCallback, setPortalVisibility]);

  const onClickDispatchUnifiedInboxOverlayEvent = () => {
    const event = new CustomEvent<UnifiedInboxOverlayEvent>(
      "unifiedInboxOverlayEvent",
      {
        detail: {
          action: "Open",
          data: {
            autoLeadId,
            customerId: customerId.toString(),
            overlayPosition: UnifiedInboxOverlayPosition.LEFT
          }
        }
      }
    );

    dispatchEvent(event);
  };

  return (
    portalFlagEnabled === true && (
      <StyledClickableIconWrapper
        className="customer-dashboard-communications-button"
        data-testid="customer-dashboard-communications-button"
        onClick={onClickDispatchUnifiedInboxOverlayEvent}
      >
        <CustomerDashboardCommunicationsIcon
          className="customer-dashboard-communications-icon"
          data-testid="customer-dashboard-communications-icon"
        />
        <span
          className="customer-dashboard-communications-text"
          data-testid="customer-dashboard-communications-text"
        >
          Inbox
        </span>
      </StyledClickableIconWrapper>
    )
  );
};

export default CustomerDashboardCommunicationsButton;
