import { useCallback, useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { LightThemeConnectLogo } from "./logo/LightThemeLogo";
import { DarkThemeConnectLogo } from "./logo/DarkThemeLogo";
import { CcrmMetricsWidgetFeature } from "@vinsolutions/ccrm/top-nav/activity";
import {
  getDealersByPage,
  getPartyBar,
  searchDealers
} from "@vinsolutions/ccrm/api";
import { TagManager } from "@vinsolutions/core-third-party-gtm";

import {
  fetchNotifications,
  fetchUserStatus,
  getMainNavigationState,
  getNavigationState,
  getProfileState,
  getThemeState,
  profileActions
} from "@vinsolutions/ccrm/store";
import {
  cardashboardActions,
  getCardashboardState
} from "@vinsolutions/legacy-cardashboard/store";

import { NavigationTabs } from "./navigation-tabs";
import { NavigationMenu } from "./navigation-menu";
import { Button } from "@vinsolutions/core/cx";
import { LoadingIndicator } from "@vinsolutions/core/components";
import { SearchBar } from "./search-bar";
import { Pendo } from "@vinsolutions/feature/pendo";
import Account from "./account/account";
import { useMountEffect } from "@vinsolutions/ccrm/util";
import { useReactOidc } from "@axa-fr/react-oidc-context";
import { DealerSelector } from "@vinsolutions/ccrm/top-nav/dealer-selector";
import HeaderStyled from "./header-styled";
import {
  VersionControl,
  minutesPriorToDeadlineToShowWarning,
  pollingInterval
} from "@vinsolutions/ccrm/top-nav/version-control";
import {
  DynamicNavigationMenu,
  DynamicNavigationTabs,
  useDynamicHeader
} from "./dynamicHeader";
// eslint-disable-next-line @nx/enforce-module-boundaries
import {
  useDealerChange,
  useGetHomePageUrl,
  useHandlerDealerIdChanges
} from "@vinsolutions/ccrm/hooks";
import { useNavigate } from "react-router-dom";
import { AnyAction } from "redux";
import { vinconnectHashPrefix } from "@vinsolutions/ccrm/util";
import { createLogger } from "@vinsolutions/logger";
import useDealListAccess from "./hooks/useDealListAccess";
import { useHistoryPushCd } from "./router";
import { useUnifiedInboxOverlayEvents } from "unified-inbox-overlay-event-listener";

const htmlId = "connect-header";
const userSelectorUrl =
  "/CarDashboard/Pages/dynamicframe.aspx?SelectedTab=t_Settings&leftpaneframe=DealerUserList.aspx&rightpaneframe=HIDE";
const viewingAsUrl =
  "/CarDashboard/Pages/dynamicframe.aspx?SelectedTab=t_Settings&leftpaneframe=DealerUserList.aspx&rightpaneframe=HIDE";
const testIdRoot = "ccrm-top-nav-main";

const logger = createLogger("VinConnect-Header");
const shouldShowActivityBar = (
  dynamicHeaderActivityBar: boolean,
  useDesking: boolean,
  showDeskingActivityBar: boolean
) => {
  if (useDesking) {
    return showDeskingActivityBar;
  } else {
    return dynamicHeaderActivityBar;
  }
};

const shouldShowNavigation = (
  dynamicHeaderComponentsNavigation: boolean,
  useDesking: boolean,
  showDeskingNavigation: boolean
) => {
  if (useDesking) {
    return showDeskingNavigation;
  } else {
    return dynamicHeaderComponentsNavigation;
  }
};

export function Header() {
  const { activeTabId } = useSelector(getMainNavigationState, shallowEqual);
  const { oidcUser } = useReactOidc();
  const { dealerChangeHandler } = useDealerChange();
  const {
    dealerId,
    dealerName,
    dealerSetupStatus,
    hasViewingAsFeature,
    isViewingAs,
    isVinEmployee,
    profileLoadingStatus,
    userFullName,
    userHasAccessToMoreThanOneDealer,
    userRole,
    viewingAsUsername
  } = useSelector(getProfileState, shallowEqual);
  const {
    dynamicHeaderLoadingStatus,
    dynamicHeaderComponents,
    dynamicMenu,
    initialLandingTarget
  } = useDynamicHeader();
  const { loadingStatus: navigationLoadingStatus } = useSelector(
    getNavigationState,
    shallowEqual
  );
  const { actionItem, background, currentThemeId, divider, secondary } =
    useSelector(getThemeState, shallowEqual);
  const { cdInitialized, frameBaseUrl, frameUrl } = useSelector(
    getCardashboardState,
    shallowEqual
  );
  const { goInitPage } = useGetHomePageUrl();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useUnifiedInboxOverlayEvents();

  useHistoryPushCd();
  useDealListAccess(logger);
  useHandlerDealerIdChanges();
  useMountEffect(() => {
    dispatch(fetchNotifications() as unknown as AnyAction);
    dispatch(fetchUserStatus() as unknown as AnyAction);
  });

  const setCarDashboardUrl = useCallback(
    (path: string, name: string) => {
      dispatch(
        cardashboardActions.updateFrameUrl({
          name,
          frameUrl: path
        })
      );
      const framePath = path?.replace(frameBaseUrl, "");
      navigate(`${vinconnectHashPrefix}${framePath}`, { replace: false });
    },
    [dispatch]
  );

  const handleUserSelectorClick = useCallback(
    (e: { button: number; ctrlKey: boolean; preventDefault: () => void }) => {
      TagManager.event({
        eventElement: "TopNav: User Selector",
        eventAction: "Clicked"
      });
      if (e.button === 0 && e.ctrlKey === false) {
        e.preventDefault();
        setCarDashboardUrl(userSelectorUrl, "user selector");
      }
    },
    [setCarDashboardUrl]
  );

  const handleViewingAsClick = useCallback(
    (e: { button: number; ctrlKey: boolean; preventDefault: () => void }) => {
      TagManager.event({
        eventElement: "TopNav: Viewing As",
        eventAction: "Clicked"
      });
      if (e.button === 0 && e.ctrlKey === false) {
        e.preventDefault();
        setCarDashboardUrl(viewingAsUrl, "viewing as");
      }
    },
    [setCarDashboardUrl]
  );

  const getDeskingComponentsToShow = useCallback(() => {
    const shouldShowSkinnyTopNav = () => {
      return frameUrl.includes("CarDashboard/Desking/DeskingLoader.ashx");
    };
    const componentsToShow = {
      useDesking: false,
      navigation: true,
      activityBar: true
    };
    if (shouldShowSkinnyTopNav()) {
      componentsToShow.activityBar = false;
      componentsToShow.navigation = false;
      componentsToShow.useDesking = true;
    }
    return componentsToShow;
  }, [frameUrl]);

  const isDarkTheme = useCallback(() => {
    return currentThemeId === "vinconnectDark";
  }, [currentThemeId]);

  const searchDealersWithJwt = async (search: string) => {
    return await searchDealers(oidcUser.access_token, search);
  };

  const getDealersByPageWithJwt = async (page: number) => {
    const resHttp = await getDealersByPage(
      oidcUser.access_token,
      page.toString()
    );
    if (!resHttp.hasError && resHttp.data && resHttp.statusCode === 200) {
      dispatch(
        profileActions.update({
          userHasAccessToMoreThanOneDealer: resHttp.data.dealers?.length > 1
        })
      );
    }
    return resHttp;
  };

  const reloadCallback = () => {
    window.location.reload();
  };

  // If using dynamic menus then goto the initial landing page
  useEffect(() => {
    if (dynamicHeaderLoadingStatus === "loaded" && dynamicMenu) {
      if (dynamicMenu.shouldUseDynamicMenu) {
        if (initialLandingTarget?.url) {
          setCarDashboardUrl(
            initialLandingTarget?.url,
            "dynamic-header-landing"
          );
        }
      }
    }
  }, [
    dynamicHeaderLoadingStatus,
    initialLandingTarget,
    dynamicMenu,
    setCarDashboardUrl
  ]);

  // we don't want the header to flash, skip rendering header until we know if we should use dynamic headers
  if (
    dynamicHeaderLoadingStatus !== "loaded" &&
    dynamicHeaderLoadingStatus !== "error"
  ) {
    return null;
  }

  return (
    <HeaderStyled.StyledHeader
      className={`connect-header-${
        currentThemeId === "vinconnectDark" ? "dark" : "light"
      }`}
      data-testid={testIdRoot}
      id={htmlId}
      {...{
        background,
        showNavigation: getDeskingComponentsToShow().navigation,
        dynamicHeaderComponents
      }}
    >
      {dynamicHeaderComponents?.versionControl ? (
        <HeaderStyled.StyledVersionControl data-testid={"version-control"}>
          {dynamicHeaderComponents?.versionControl && (
            <VersionControl
              {...{
                minutesPriorToDeadlineToShowWarning,
                pollingInterval,
                reloadCallback
              }}
            />
          )}
        </HeaderStyled.StyledVersionControl>
      ) : (
        <HeaderStyled.StyledVersionControl
          data-testid={"version-control-hidden"}
        />
      )}
      <Pendo />
      {dynamicHeaderComponents?.logo && (
        <HeaderStyled.StyledLogo
          data-testid={`${testIdRoot}-logo`}
          id={`${htmlId}-logo`}
          onClick={goInitPage}
        >
          {isDarkTheme() ? (
            <DarkThemeConnectLogo className="cx-top-nav-logo" />
          ) : (
            <LightThemeConnectLogo className="cx-top-nav-logo" />
          )}
        </HeaderStyled.StyledLogo>
      )}
      {shouldShowActivityBar(
        Boolean(dynamicHeaderComponents?.activityBar),
        getDeskingComponentsToShow().useDesking,
        getDeskingComponentsToShow().activityBar
      ) && (
        <HeaderStyled.StyledActivity
          data-testid={`${testIdRoot}-activity-bar`}
          id={`${htmlId}-activity-bar`}
        >
          {cdInitialized === true && dealerId !== null && (
            <CcrmMetricsWidgetFeature
              isDarkTheme={isDarkTheme()}
              {...{
                dealerId,
                userRole,
                getPartyBar,
                setCarDashboardUrl
              }}
            />
          )}
        </HeaderStyled.StyledActivity>
      )}
      {(dynamicHeaderComponents?.userSelector ||
        dynamicHeaderComponents?.dealerSelector) && (
        <HeaderStyled.StyledUserContext
          data-testid={`${testIdRoot}-profile-context`}
          divider={dynamicHeaderComponents?.userSelector ? divider : "0px"}
          id={`${htmlId}-profile-context`}
          {...{
            actionItem
          }}
        >
          {profileLoadingStatus === "loaded" && dealerId !== null ? (
            <>
              {dynamicHeaderComponents?.dealerSelector && (
                <DealerSelector
                  dealerStatus={dealerSetupStatus}
                  getDealersByPage={getDealersByPageWithJwt}
                  isEmployee={isVinEmployee}
                  searchDealers={searchDealersWithJwt}
                  {...{
                    dealerId,
                    dealerName,
                    onDealerChanged: dealerChangeHandler
                  }}
                />
              )}
              {dynamicHeaderComponents?.userSelector && (
                <HeaderStyled.StyledUserSelector
                  data-testid={"user-selector"}
                  id="user-selector"
                  {...{ actionItem }}
                >
                  {userRole !== "Salesperson" ? (
                    <Button
                      buttonStyle="link"
                      htmlId="user-selector-button"
                      size="small"
                      onClick={handleUserSelectorClick}
                    >
                      <a href={frameBaseUrl + userSelectorUrl}>
                        {userFullName}
                      </a>
                    </Button>
                  ) : (
                    <Button
                      buttonStyle="link"
                      className={
                        userRole === "Salesperson" ? "unclickable" : undefined
                      }
                      htmlId="user-selector-button"
                      size="small"
                    >
                      {userFullName}
                    </Button>
                  )}
                  {hasViewingAsFeature && isViewingAs && (
                    <Button
                      buttonStyle="link"
                      htmlId="user-selector-viewing-as-button"
                      size="small"
                      onClick={handleViewingAsClick}
                    >
                      <a href={frameBaseUrl + viewingAsUrl}>
                        viewing as {viewingAsUsername}
                      </a>
                    </Button>
                  )}
                </HeaderStyled.StyledUserSelector>
              )}
            </>
          ) : (
            <div id="user-context-loading-indicator-wrapper">
              <LoadingIndicator
                htmlId="user-context-loading-indicator"
                size="large"
              />
            </div>
          )}
        </HeaderStyled.StyledUserContext>
      )}
      <HeaderStyled.StyledAccount
        data-testid={`${htmlId}-account-menu`}
        id={`${htmlId}-account-menu`}
      >
        {dynamicHeaderComponents?.account && <Account />}
      </HeaderStyled.StyledAccount>
      {shouldShowNavigation(
        Boolean(dynamicHeaderComponents?.navigation),
        getDeskingComponentsToShow().useDesking,
        getDeskingComponentsToShow().navigation
      ) && (
        <>
          <HeaderStyled.StyledTabs
            data-testid={`${testIdRoot}-tabs`}
            id={`${htmlId}-tabs`}
          >
            {dynamicMenu?.shouldUseDynamicMenu ? (
              <DynamicNavigationTabs />
            ) : (
              navigationLoadingStatus === "loaded" && <NavigationTabs />
            )}
          </HeaderStyled.StyledTabs>
          <HeaderStyled.StyledSearch
            data-testid={`${testIdRoot}-search`}
            id={`${htmlId}-search`}
          >
            {dynamicHeaderComponents?.customerSearch && (
              <SearchBar
                hasAdvancedCustomerSearch={userHasAccessToMoreThanOneDealer} // TODO: check if more than on dealership for user
                setCarDashboardUrl={setCarDashboardUrl}
              />
            )}
          </HeaderStyled.StyledSearch>
          {dynamicHeaderComponents?.navigation && (
            <HeaderStyled.StyledNavigation
              data-testid={`${testIdRoot}-navigation`}
              id={`${htmlId}-navigation`}
              {...{ secondary }}
            >
              {dynamicHeaderComponents?.navigation &&
                (dynamicMenu?.shouldUseDynamicMenu ? (
                  <DynamicNavigationMenu />
                ) : (
                  navigationLoadingStatus === "loaded" &&
                  activeTabId && <NavigationMenu />
                ))}
            </HeaderStyled.StyledNavigation>
          )}
        </>
      )}
    </HeaderStyled.StyledHeader>
  );
}

export default Header;
