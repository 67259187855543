import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState
} from "react";
import { OverlayPosition } from "../enums/overlay-position";

declare global {
  interface Window extends OverlayContextType {
    doNothing: null;
  }
}

interface Overlay {
  position: OverlayPosition;
  isFullScreen: boolean;
  params?: any;
}

interface OverlayContextType {
  openOverlay: (
    overlayName: string,
    position: OverlayPosition,
    params?: any
  ) => void;

  closeOverlay: (overlayName: string) => void;

  hideOverlays: () => void;

  showOverlays: () => void;

  switchOverlayPosition: (overlayName: string) => void;

  toggleOverlaySize: (overlayName: string) => void;

  activeOverlays: Map<string, Overlay>;

  hidden: boolean;
}

interface OverlayState {
  active: Map<string, Overlay>;
  hidden: boolean;
}

const OverlayContext = createContext<OverlayContextType | undefined>(undefined);

/**
 * OverlayProvider component that provides overlay context to its children.
 *
 * @param {Object} props - The props for the provider.
 * @param {ReactNode} props.children - The child components that will have access to the overlay context.
 * @returns {JSX.Element} The provider component.
 */
export const OverlayProvider = ({
  children
}: {
  children: ReactNode;
}): JSX.Element => {
  const [overlayState, setOverlayState] = useState<OverlayState>({
    active: new Map(),
    hidden: false
  });

  /**
   * Opens an overlay.
   *
   * @param {string} name - The name of the overlay.
   * @param {OverlayPosition} position - The position of the overlay.
   * @param {any} [params] - Optional parameters for the overlay.
   */
  const openOverlay = useCallback(
    (name: string, position: OverlayPosition, params?: any) => {
      setOverlayState(prev => ({
        active: new Map(prev.active).set(name, {
          position,
          isFullScreen: false,
          params
        }),
        hidden: false
      }));
    },
    []
  );

  /**
   * Closes an overlay.
   *
   * @param {string} name - The name of the overlay.
   */
  const closeOverlay = useCallback((name: string) => {
    setOverlayState(prev => {
      const newActive = new Map(prev.active);
      newActive.delete(name);
      return { ...prev, active: newActive };
    });
  }, []);

  /**
   * Hides all overlays.
   */
  const hideOverlays = useCallback(() => {
    setOverlayState(prev => ({
      ...prev,
      hidden: prev.active.size > 0 ? true : false
    }));
  }, []);

  /**
   * Shows all overlays.
   */
  const showOverlays = useCallback(() => {
    setOverlayState(prev => {
      return {
        ...prev,
        hidden: false
      };
    });
  }, []);

  /**
   * Switches the position of an overlay.
   *
   * @param {string} name - The name of the overlay.
   */
  const switchOverlayPosition = useCallback((name: string) => {
    setOverlayState(prev => {
      const newActive = new Map(prev.active);
      const overlay = newActive.get(name);

      if (overlay) {
        newActive.set(name, {
          ...overlay,
          position:
            overlay.position === OverlayPosition.LEFT
              ? OverlayPosition.RIGHT
              : OverlayPosition.LEFT
        });
      }

      return { ...prev, active: newActive };
    });
  }, []);

  /**
   * Toggles the size of an overlay between full screen and its default size.
   *
   * @param {string} name - The name of the overlay.
   */
  const toggleOverlaySize = useCallback((name: string) => {
    setOverlayState(prev => {
      const newActive = new Map(prev.active);
      const overlay = newActive.get(name);

      if (overlay) {
        newActive.set(name, {
          ...overlay,
          isFullScreen: !overlay.isFullScreen
        });
      }

      return { ...prev, active: newActive };
    });
  }, []);

  const contextValue = useMemo(
    () => ({
      openOverlay,
      closeOverlay,
      hideOverlays,
      showOverlays,
      switchOverlayPosition,
      toggleOverlaySize,
      activeOverlays: overlayState.active,
      hidden: overlayState.hidden
    }),
    [
      openOverlay,
      closeOverlay,
      hideOverlays,
      showOverlays,
      switchOverlayPosition,
      toggleOverlaySize,
      overlayState.active,
      overlayState.hidden
    ]
  );

  return (
    <OverlayContext.Provider value={contextValue}>
      {children}
    </OverlayContext.Provider>
  );
};

/**
 * Custom hook to use the overlay context.
 *
 * @throws Will throw an error if used outside of an OverlayProvider.
 * @returns {OverlayContextType} The overlay context.
 */
export const useOverlay = (): OverlayContextType => {
  const context = useContext(OverlayContext);

  if (!context) {
    throw new Error("useOverlay must be used within an OverlayProvider");
  }

  return context;
};
