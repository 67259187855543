import { useEffect, useState } from "react";
import { buildCopyLinkObject } from "../helper";
import { CopyLinkSettings } from "../interfaces/deal-interaction-copy-link-settings";
import { DealInteractionProps } from "../interfaces/deal-interaction-props";

const useCopyLink = ({ dealSettings, onHandleModal }: DealInteractionProps) => {
  const [copyLinkSettings, setCopyLinkSettings] =
    useState<CopyLinkSettings | null>(null);

  useEffect(() => {
    if (dealSettings && dealSettings.isCopyLinkButtonVisible) {
      const copyLinkSettings = buildCopyLinkObject(dealSettings, onHandleModal);
      setCopyLinkSettings(copyLinkSettings);
    }
  }, [dealSettings, onHandleModal]);

  return copyLinkSettings;
};

export default useCopyLink;
