import { Box } from "@interstate/components/Box";
import {
  RadioButtonEventValue,
  RadioButtonList
} from "@interstate/components/RadioButtonList";
import { InterstateOnChangeEvent } from "@interstate/components/InterstateEvents";
import { useState } from "react";
import { FeatureMarkLeadStatus } from "./feature-mark-lead-status";
import { AssignedLeadType } from "@vinsolutions/data-access/lead/crm.lead.bff";

export interface MarkBadLostProps {
  dealerId: number;
  leadId: number;
  onClose: () => void;
}

export function MarkBadLost({ dealerId, leadId, onClose }: MarkBadLostProps) {
  const [assignedLeadType, setAssignedLeadType] = useState<AssignedLeadType>(
    AssignedLeadType.Bad
  );

  function onLostBadChange(
    event: InterstateOnChangeEvent<RadioButtonEventValue>
  ) {
    if (!event.isValid || !event.target.value) {
      return;
    }

    setAssignedLeadType(
      event.target.value === "Bad"
        ? AssignedLeadType.Bad
        : AssignedLeadType.Lost
    );
  }

  return (
    <Box margin={"1em"}>
      <RadioButtonList
        inline
        label="Assign Lead"
        name="badLostList"
        options={[
          {
            label: "Bad",
            value: "Bad"
          },
          {
            label: "Lost",
            value: "Lost"
          }
        ]}
        required={true}
        value={assignedLeadType}
        onChange={event => onLostBadChange(event)}
      />

      <FeatureMarkLeadStatus
        assignedLeadType={assignedLeadType}
        dealerId={dealerId}
        leadId={leadId}
        onCancel={onClose}
        onClose={onClose}
      />
    </Box>
  );
}

export default MarkBadLost;
