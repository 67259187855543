import { useEffect, useState } from "react";
import { buildAccelerateObject } from "../helper";
import { DealInteractionProps } from "../interfaces/deal-interaction-props";
import { DropdownOptions } from "@vinsolutions/core-cx";
const defaultDealInteractionSettings = { enabled: false, options: [] };
const useDealInteractionDropdown = ({
  dealSettings,
  onHandleModal
}: DealInteractionProps) => {
  const [dealInteractionSettings, setDealInteractionSettings] = useState<{
    options: DropdownOptions[];
    enabled: boolean;
  }>(defaultDealInteractionSettings);

  useEffect(() => {
    if (dealSettings && dealSettings.isAccelerateDropdownButtonVisible) {
      const dropdownSettings = buildAccelerateObject(
        dealSettings,
        onHandleModal
      );

      const options = [
        {
          label: "Copy Link",
          onSelect: dropdownSettings.copyClick,
          value: "copy"
        },
        {
          label: dropdownSettings.proposalLabel,
          onSelect: dropdownSettings.proposalClick,
          value: "proposal"
        }
      ];
      setDealInteractionSettings({
        options,
        enabled: dropdownSettings.enabled
      });
    } else {
      setDealInteractionSettings(defaultDealInteractionSettings);
    }
  }, [dealSettings, onHandleModal]);

  return dealInteractionSettings;
};

export default useDealInteractionDropdown;
