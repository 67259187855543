import { useDispatch, useSelector } from "react-redux";
import { Grid } from "@interstate/components/Grid";
import { Switch } from "@interstate/components/Switch";
import { DatePicker } from "@interstate/components/DatePicker";
import {
  getAuthSettingsState,
  setAuthenticationRule
} from "../../slices/auth-settings";

export const AuthSettingsAuthenticationRulesEnablement = () => {
  const dispatch = useDispatch();
  const { authenticationRule } = useSelector(getAuthSettingsState);

  const updateEnablement = (
    key: "notValidUntil" | "notValidAfter" | "disabled",
    value: string | boolean
  ) => {
    const updatedAuthenticationRule = {
      ...authenticationRule,
      enablement: {
        ...authenticationRule.enablement,
        [key]: value
      }
    };

    dispatch(setAuthenticationRule(updatedAuthenticationRule));
  };

  return (
    <Grid container columnSpacing={1} rowSpacing={1}>
      <Grid xs={6}>
        <DatePicker
          id="oem-auth-settings-authentication-rules-not-valid-until"
          label="Not Valid Until"
          name="enablement.notValidUntil"
          value={new Date(authenticationRule.enablement.notValidUntil)}
          onChange={event => {
            const { value } = event.target;
            const isoDate = value?.additionalFormats?.iso;
            const date = new Date(isoDate || "0001-01-01");

            updateEnablement("notValidUntil", date.toISOString());
          }}
        />
      </Grid>
      <Grid xs={6}>
        <DatePicker
          id="oem-auth-settings-authentication-rules-not-valid-after"
          label="Not Valid After"
          name="enablement.notValidAfter"
          value={new Date(authenticationRule.enablement.notValidAfter)}
          onChange={event => {
            const { value } = event.target;
            const isoDate = value?.additionalFormats?.iso;
            const date = new Date(isoDate || "9999-12-31");

            date.setHours(23, 59, 59, 999);

            updateEnablement("notValidAfter", date.toISOString());
          }}
        />
      </Grid>
      <Grid xs={6}>
        <Switch
          checked={!authenticationRule.enablement?.disabled}
          id="oem-auth-settings-authentication-rules-disable"
          label="Status"
          layout="vertical"
          name="enablement.disabled"
          onClick={() =>
            updateEnablement(
              "disabled",
              !authenticationRule.enablement?.disabled
            )
          }
        />
      </Grid>
    </Grid>
  );
};
