export const ERROR_MSG = "Sorry, something went wrong";

export const SUCCESS_MSG_ON_SAVE = "Saved successfully!";

export const SUCCESS_MSG_ON_DELETE = "Deleted successfully!";

export const ERROR_MSG_ON_DELETE_AUTHENTICATION_RULES =
  "Each OEM requires at least one authentication rule. If no authentication rules are needed, disable or delete the entire OEM.";

export const ERROR_MSG_ON_DELETE_PERMISSIONS =
  "Each OEM requires at least one permission. If no permissions are needed, disable or delete the entire OEM.";
