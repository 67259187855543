import {
  BaseUrlsObj,
  envEnum,
  getBaseUrl
} from "@vinsolutions/core/environment";
import { fetchWrapper } from "@vinsolutions/core/http";
import { WorkflowGatewayConfig } from "./crm.workflow.api";
import { createLogger } from "@vinsolutions/logger";
import { LeadInformation } from "./models/lead-information";
import { User } from "./models/user";
import { VehicleInformation } from "./models/vehicle-information";
import { VehicleMake } from "./models/vehicle-make";
import { VehicleModel } from "./models/vehicle-model";
import { VehicleTrim } from "./models/vehicle-trim";
import { getLeadInformationMock } from "./mocks/mock-getLeadInformation";
import { getLeadIntentsMock } from "./mocks/mock-getLeadIntents";
import { getLeadTypesMock } from "./mocks/mock-getLeadTypes";
import { getLeadSourcesMock } from "./mocks/mock-getLeadSources";
import { getLeadStatusesMock } from "./mocks/mock-getLeadStatuses";
import { getUsersMock } from "./mocks/mock-getUsers";
import { getUserPermissionsMock } from "./mocks/mock-getUserPermissions";
import { getVehicleYearsMock } from "./mocks/mock-getVehicleYears";
import { getVehicleMakesMock } from "./mocks/mock-getVehicleMakes";
import { getVehicleModelsMock } from "./mocks/mock-getVehicleModels";
import { getVehicleTrimsMock } from "./mocks/mock-getVehicleTrims";
import { getVehicleInformationMock } from "./mocks/mock-getVehicleInformation";
import { LeadMetadata } from "./models/lead-metadata";
import { UserPermissions } from "./models/user-permissions";
import { CustomProcess } from "./models/custom-process";
import { getCustomProcessesMock } from "./mocks/mock-getCustomProcesses";
import { DealerSettings } from "./models/dealer-settings";
import { getDealerSettingsMock } from "./mocks/mock-getDealerSettings";
import { CustomerSettings } from "./models/customer-settings";
import { getCustomerSettingsMock } from "./mocks/mock-getCustomerSettings";
import { Reason } from "./models/reason";
import { getReasonsMock } from "./mocks/mock-getReasons";
import { AssignedLeadType } from "./enums/assigned-lead-type";

const logger = createLogger("data-access-lead-crm.workflow.lead");

type Config = {
  baseUrls: BaseUrlsObj;
};

const config: Config = {
  baseUrls: {
    [envEnum.LOCAL]: `${WorkflowGatewayConfig.baseUrls.dev}crm/lead/bff/`,
    [envEnum.DEV]: `${WorkflowGatewayConfig.baseUrls.dev}crm/lead/bff/`,
    [envEnum.QA]: `${WorkflowGatewayConfig.baseUrls.qa}crm/lead/bff/`,
    [envEnum.STAGING]: `${WorkflowGatewayConfig.baseUrls.staging}crm/lead/bff/`,
    [envEnum.PRODUCTION]: `${WorkflowGatewayConfig.baseUrls.production}crm/lead/bff/`
  }
};

// TODO: Rename projects, move endpoints around as needed to reflect actual BFF configurations

export async function createLead(
  jwt: string,
  leadInformation: LeadInformation,
  useMockData?: boolean
): Promise<string | null | undefined> {
  if (!leadInformation) {
    throw new Error("Invalid lead Information");
  }

  if (useMockData) {
    return "claim";
  }

  const baseUrl = getBaseUrl(config.baseUrls);
  const url = `${baseUrl}`;
  const response = await fetchWrapper.postAsync<string>(url, leadInformation, {
    Authorization: `Bearer ${jwt}`,
    Accept: "application/vnd.coxauto.v1+json"
  });

  if (response.statusCode !== 200) {
    logger.error("Error updating vehicle", JSON.stringify(response));
    return null;
  }

  return response.data;
}

export async function getLeadCreationStatus(
  jwt: string,
  token: string,
  useMockData?: boolean
): Promise<number | null | undefined> {
  if (!token) {
    throw new Error("Invalid lead token");
  }

  if (useMockData) {
    await new Promise(resolve => setTimeout(resolve, 750));
    return Math.random() > 0.5 ? 1370572508 : null;
  }

  const baseUrl = getBaseUrl(config.baseUrls);
  const url = `${baseUrl}status/token/${token}`;
  const response = await fetchWrapper.getAsync<number>(url, {
    Authorization: `Bearer ${jwt}`,
    Accept: "application/vnd.coxauto.v1+json"
  });

  if (response.statusCode !== 200) {
    logger.error("Error getting lead status", JSON.stringify(response));
    return null;
  }

  return response.data;
}

export async function updateLead(
  jwt: string,
  leadInformation: LeadInformation,
  useMockData?: boolean
): Promise<boolean | null | undefined> {
  if (!leadInformation?.id || leadInformation.id <= 0) {
    throw new Error("Invalid lead Information");
  }

  if (useMockData) {
    return true;
  }

  const baseUrl = getBaseUrl(config.baseUrls);
  const url = `${baseUrl}id/${leadInformation.id}`;
  const response = await fetchWrapper.putAsync(url, leadInformation, {
    Authorization: `Bearer ${jwt}`,
    Accept: "application/vnd.coxauto.v1+json"
  });

  if (response.statusCode !== 200) {
    logger.error("Error updating lead", JSON.stringify(response));
    return false;
  }

  return true;
}

export async function updateVehicle(
  leadId: number,
  jwt: string,
  vehicleInformation: VehicleInformation,
  useMockData?: boolean
): Promise<boolean | null | undefined> {
  if (!leadId || !vehicleInformation) {
    throw new Error("Invalid leadId or vehicleInformation");
  }

  if (useMockData) {
    return true;
  }

  const baseUrl = getBaseUrl(config.baseUrls);
  const url = `${baseUrl}id/${leadId}/vehicle`;
  const response = await fetchWrapper.putAsync(url, vehicleInformation, {
    Authorization: `Bearer ${jwt}`,
    Accept: "application/vnd.coxauto.v1+json"
  });

  if (response.statusCode !== 200) {
    logger.error("Error updating vehicle", JSON.stringify(response));
    return false;
  }

  return true;
}

export async function deleteVehicle(
  leadId: number,
  jwt: string,
  useMockData?: boolean
): Promise<boolean | null | undefined> {
  if (!leadId) {
    throw new Error("Invalid leadId");
  }

  if (useMockData) {
    return true;
  }

  const baseUrl = getBaseUrl(config.baseUrls);
  const url = `${baseUrl}id/${leadId}/vehicle`;
  const response = await fetchWrapper.deleteAsync(url, {
    Authorization: `Bearer ${jwt}`,
    Accept: "application/vnd.coxauto.v1+json"
  });

  if (response.statusCode !== 200) {
    logger.error("Error deleting vehicle", JSON.stringify(response));
    return false;
  }

  return true;
}

export async function getDealerSettings(
  dealerId: number,
  jwt: string,
  useMockData?: boolean
): Promise<DealerSettings | null | undefined> {
  if (!dealerId) {
    throw new Error("Invalid dealerId");
  }

  if (useMockData) {
    return getDealerSettingsMock;
  }

  const baseUrl = getBaseUrl(config.baseUrls);
  const url = `${baseUrl}dealer/id/${dealerId}/settings`;
  const response = await fetchWrapper.getAsync<DealerSettings>(url, {
    Authorization: `Bearer ${jwt}`,
    Accept: "application/vnd.coxauto.v1+json"
  });

  if (response.statusCode !== 200) {
    logger.error("Error getting dealer settings", JSON.stringify(response));
    return null;
  }

  return response.data;
}

export async function getCustomerSettings(
  customerId: number,
  jwt: string,
  useMockData?: boolean
): Promise<CustomerSettings | null | undefined> {
  if (!customerId) {
    throw new Error("Invalid customerId");
  }

  if (useMockData) {
    return getCustomerSettingsMock;
  }

  const baseUrl = getBaseUrl(config.baseUrls);
  const url = `${baseUrl}customer/id/${customerId}/settings`;
  const response = await fetchWrapper.getAsync<CustomerSettings>(url, {
    Authorization: `Bearer ${jwt}`,
    Accept: "application/vnd.coxauto.v1+json"
  });

  if (response.statusCode !== 200) {
    logger.error("Error getting customer settings", JSON.stringify(response));
    return null;
  }

  return response.data;
}

export async function getLeadInformation(
  leadId: number,
  jwt: string,
  useMockData?: boolean
): Promise<LeadInformation | null | undefined> {
  if (!leadId) {
    throw new Error("Invalid leadId");
  }

  if (useMockData) {
    return getLeadInformationMock.find(s => s.id === leadId);
  }

  const baseUrl = getBaseUrl(config.baseUrls);
  const url = `${baseUrl}id/${leadId}`;
  const response = await fetchWrapper.getAsync<LeadInformation>(url, {
    Authorization: `Bearer ${jwt}`,
    Accept: "application/vnd.coxauto.v1+json"
  });

  if (response.statusCode !== 200) {
    logger.error("Error getting lead information", JSON.stringify(response));
    return null;
  }

  return response.data;
}

export async function getLeadCount(
  customerId: number,
  jwt: string,
  useMockData?: boolean
): Promise<number | null | undefined> {
  if (useMockData) {
    return Math.random() > 0.5 ? 1 : 0;
  }

  const baseUrl = getBaseUrl(config.baseUrls);
  const url = `${baseUrl}count?customerId=${customerId}`;
  const response = await fetchWrapper.getAsync<number>(url, {
    Authorization: `Bearer ${jwt}`,
    Accept: "application/vnd.coxauto.v1+json"
  });

  if (response.statusCode !== 200) {
    logger.error("Error getting lead count", JSON.stringify(response));
    return null;
  }

  return response.data;
}

export async function getLeadMetaData(
  dealerId: number,
  jwt: string,
  useMockData?: boolean
): Promise<LeadMetadata | null | undefined> {
  if (useMockData) {
    return {
      leadIntents: getLeadIntentsMock,
      leadSources: getLeadSourcesMock,
      leadStatuses: getLeadStatusesMock,
      leadTypes: getLeadTypesMock
    };
  }

  const baseUrl = getBaseUrl(config.baseUrls);
  const url = `${baseUrl}metadata?dealerId=${dealerId}`;
  const response = await fetchWrapper.getAsync<LeadMetadata>(url, {
    Authorization: `Bearer ${jwt}`,
    Accept: "application/vnd.coxauto.v1+json"
  });

  if (response.statusCode !== 200) {
    logger.error("Error getting lead intents", JSON.stringify(response));
    return null;
  }

  return response.data;
}

export async function getUsers(
  dealerId: number,
  jwt: string,
  useMockData?: boolean
): Promise<User[] | null | undefined> {
  if (useMockData) {
    return getUsersMock;
  }

  const baseUrl = getBaseUrl(config.baseUrls);
  const url = `${baseUrl}users?dealerId=${dealerId}`;
  const response = await fetchWrapper.getAsync<User[]>(url, {
    Authorization: `Bearer ${jwt}`,
    Accept: "application/vnd.coxauto.v1+json"
  });

  if (response.statusCode !== 200) {
    logger.error("Error getting users", JSON.stringify(response));
    return null;
  }

  return response.data;
}

export async function getProcesses(
  dealerId: number,
  jwt: string,
  useMockData?: boolean
): Promise<CustomProcess[] | null | undefined> {
  if (useMockData) {
    return getCustomProcessesMock;
  }

  const baseUrl = getBaseUrl(config.baseUrls);
  const url = `${baseUrl}processes?dealerId=${dealerId}`;
  const response = await fetchWrapper.getAsync<CustomProcess[]>(url, {
    Authorization: `Bearer ${jwt}`,
    Accept: "application/vnd.coxauto.v1+json"
  });

  if (response.statusCode !== 200) {
    logger.error("Error getting custom processes", JSON.stringify(response));
    return null;
  }

  return response.data;
}

export async function getReasons(
  assignedLeadType: AssignedLeadType,
  dealerId: number,
  jwt: string,
  useMockData?: boolean
): Promise<Reason[] | null | undefined> {
  if (useMockData) {
    return getReasonsMock;
  }

  const baseUrl = getBaseUrl(config.baseUrls);
  const url = `${baseUrl}reasons?dealerId=${dealerId}&assignedLeadType=${assignedLeadType}`;
  const response = await fetchWrapper.getAsync<Reason[]>(url, {
    Authorization: `Bearer ${jwt}`,
    Accept: "application/vnd.coxauto.v1+json"
  });

  if (response.statusCode !== 200) {
    logger.error("Error getting reasons", JSON.stringify(response));
    return null;
  }

  return response.data;
}

export async function getUserPermissions(
  dealerId: number,
  userId: number,
  jwt: string,
  useMockData?: boolean
): Promise<UserPermissions | null | undefined> {
  if (useMockData) {
    return getUserPermissionsMock;
  }

  const baseUrl = getBaseUrl(config.baseUrls);
  const url = `${baseUrl}permissions?dealerId=${dealerId}&userId=${userId}`;
  const response = await fetchWrapper.getAsync<UserPermissions>(url, {
    Authorization: `Bearer ${jwt}`,
    Accept: "application/vnd.coxauto.v1+json"
  });

  if (response.statusCode !== 200) {
    logger.error("Error getting user permissions", JSON.stringify(response));
    return null;
  }

  return response.data;
}

export async function getVehicleInformationFromVin(
  vin: string,
  jwt: string,
  useMockData?: boolean
): Promise<VehicleInformation | null | undefined> {
  if (!vin) {
    throw new Error("Invalid VIN");
  }

  if (useMockData) {
    return { ...getVehicleInformationMock, vehicleId: 0 };
  }

  const baseUrl = getBaseUrl(config.baseUrls);
  const url = `${baseUrl}decode/${vin}/vehicle`;
  const response = await fetchWrapper.getAsync<VehicleInformation>(url, {
    Authorization: `Bearer ${jwt}`,
    Accept: "application/vnd.coxauto.v1+json"
  });

  if (response.statusCode !== 200) {
    logger.error("Error getting vehicle information", JSON.stringify(response));
    return null;
  }

  return response.data;
}

export async function getVehicleInformation(
  leadId: number,
  jwt: string,
  useMockData?: boolean
): Promise<VehicleInformation | null | undefined> {
  if (!leadId) {
    throw new Error("Invalid leadId");
  }

  if (useMockData) {
    return getVehicleInformationMock;
  }

  const baseUrl = getBaseUrl(config.baseUrls);
  const url = `${baseUrl}id/${leadId}/vehicle`;
  const response = await fetchWrapper.getAsync<VehicleInformation>(url, {
    Authorization: `Bearer ${jwt}`,
    Accept: "application/vnd.coxauto.v1+json"
  });

  if (response.statusCode !== 200) {
    logger.error("Error getting vehicle information", JSON.stringify(response));
    return null;
  }

  return response.data;
}

export async function getVehicleYears(
  dealerId: number,
  jwt: string,
  useMockData?: boolean
): Promise<number[] | null | undefined> {
  if (!dealerId) {
    throw new Error("Invalid dealerId");
  }

  if (useMockData) {
    return getVehicleYearsMock;
  }

  const baseUrl = getBaseUrl(config.baseUrls);
  const url = `${baseUrl}dealer/${dealerId}/vehicle/years`;
  const response = await fetchWrapper.getAsync<number[]>(url, {
    Authorization: `Bearer ${jwt}`,
    Accept: "application/vnd.coxauto.v1+json"
  });

  if (response.statusCode !== 200) {
    logger.error("Error getting vehicle years", JSON.stringify(response));
    return null;
  }

  return response.data;
}

export async function getVehicleMakes(
  dealerId: number,
  jwt: string,
  useMockData?: boolean
): Promise<VehicleMake[] | null | undefined> {
  if (!dealerId) {
    throw new Error("Invalid dealerId");
  }

  if (useMockData) {
    return getVehicleMakesMock;
  }

  const baseUrl = getBaseUrl(config.baseUrls);
  const vehicleUrl = `${baseUrl}dealer/${dealerId}/vehicle/makes`;
  const response = await fetchWrapper.getAsync<VehicleMake[]>(vehicleUrl, {
    Authorization: `Bearer ${jwt}`,
    Accept: "application/vnd.coxauto.v1+json"
  });

  if (response.statusCode !== 200) {
    logger.error("Error getting vehicle makes", JSON.stringify(response));
    return null;
  }

  return response.data;
}

export async function getVehicleModels(
  dealerId: number,
  jwt: string,
  makeId: number,
  useMockData?: boolean
): Promise<VehicleModel[] | null | undefined> {
  if (!dealerId) {
    throw new Error("Invalid dealerId");
  }

  if (useMockData) {
    return getVehicleModelsMock;
  }

  const baseUrl = getBaseUrl(config.baseUrls);
  const url = `${baseUrl}dealer/${dealerId}/vehicle/make/id/${makeId}/models`;
  const response = await fetchWrapper.getAsync<VehicleModel[]>(url, {
    Authorization: `Bearer ${jwt}`,
    Accept: "application/vnd.coxauto.v1+json"
  });

  if (response.statusCode !== 200) {
    logger.error("Error getting vehicle makes", JSON.stringify(response));
    return null;
  }

  return response.data;
}

export async function getVehicleTrims(
  dealerId: number,
  jwt: string,
  makeId: number,
  modelId: number,
  useMockData?: boolean
): Promise<VehicleTrim[] | null | undefined> {
  if (!dealerId) {
    throw new Error("Invalid dealerId");
  }

  if (useMockData) {
    return getVehicleTrimsMock;
  }

  const baseUrl = getBaseUrl(config.baseUrls);
  const url = `${baseUrl}dealer/${dealerId}/vehicle/make/id/${makeId}/model/id/${modelId}/trims`;
  const response = await fetchWrapper.getAsync<VehicleTrim[]>(url, {
    Authorization: `Bearer ${jwt}`,
    Accept: "application/vnd.coxauto.v1+json"
  });

  if (response.statusCode !== 200) {
    logger.error("Error getting vehicle makes", JSON.stringify(response));
    return null;
  }

  return response.data;
}
