import { DealInteractionErrorsProps } from "../interfaces/deal-interaction-errors-props";
import styled from "styled-components";

const StyledError = styled("div")`
  color: red;
  padding-top: 5px;
  div {
    padding-top: 5px;
    padding-bottom: 5px;
  }
`;

export const DealInteractionErrors = ({
  dealDataErrors
}: DealInteractionErrorsProps) => {
  if (dealDataErrors && dealDataErrors.length) {
    return (
      <StyledError id="deal-interaction-errors">
        {dealDataErrors.map(({ errorMessage, dataKey }) => (
          <div key={dataKey}>{errorMessage}</div>
        ))}
      </StyledError>
    );
  }
  return null;
};
