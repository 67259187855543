import { format } from "date-fns";
import { TableColumns } from "@interstate/components/Table";
import { PencilSquareIcon } from "@interstate/components/Icons";
import { AuthSettingsBadge } from "../auth-settings-badge/authSettingsBadge.component";
import { AuthenticationType } from "@vinsolutions/data-access/oem/coxauto.lead.oem.api";
import { EnrichedAuthenticationRule } from "../../types/authSettings.type";
import { AuthSettingsAuthenticationRulesDeleteButton } from "./authSettingsAuthenticationRulesDeleteButton.component";
import { AuthSettingsAuthenticationRulesActionButton } from "./authSettingsAuthenticationRulesActionButton.component";
import { Grid } from "@interstate/components/Grid";

export const AuthenticationRulesTableColumnsConfig: TableColumns = [
  {
    fixed: "left",
    width: 100,
    render: (_: unknown, record: EnrichedAuthenticationRule) => {
      return (
        <Grid container>
          <Grid xs={6}>
            <AuthSettingsAuthenticationRulesActionButton
              buttonIcon={<PencilSquareIcon />}
              initialAuthenticationRule={record}
              isEditMode={true}
            />
          </Grid>
          <Grid xs={6}>
            <AuthSettingsAuthenticationRulesDeleteButton rule={record} />
          </Grid>
        </Grid>
      );
    }
  },
  {
    title: "Status",
    dataIndex: "enablement.disabled",
    width: 100,
    render: (_: unknown, record: EnrichedAuthenticationRule) => {
      return <AuthSettingsBadge disabled={record.enablement.disabled} />;
    }
  },
  {
    title: "Type",
    dataIndex: "authenticationType",
    width: 100,
    render: (_: unknown, record: EnrichedAuthenticationRule) => {
      return <label>{record.authenticationType}</label>;
    }
  },
  {
    title: "Title",
    dataIndex: "details.title",
    width: 250,
    render: (_: unknown, record: EnrichedAuthenticationRule) => {
      return <label>{record.details.title}</label>;
    }
  },
  {
    title: "Description",
    dataIndex: "details.description",
    width: 350,
    render: (_: unknown, record: EnrichedAuthenticationRule) => {
      return <label>{record.details.description}</label>;
    }
  },
  {
    title: "Details",
    dataIndex: "details",
    width: 200,
    render: (_: unknown, record: EnrichedAuthenticationRule) => {
      let details = "";

      switch (record.authenticationType) {
        case AuthenticationType.OAUTH2:
          details = record.oAuth2Details?.clientId || "";
          break;
        case AuthenticationType.API_KEY:
          details = "API Key";
          break;
        case AuthenticationType.BASIC:
        default:
          details = "Username/Password";
          break;
      }

      return <label>{details}</label>;
    }
  },
  {
    title: "Not Valid Until(MM-DD-YYYY)",
    dataIndex: "enablement.notValidUntil",
    width: 200,
    render: (_: unknown, record: EnrichedAuthenticationRule) => {
      const notValidUntil = record.enablement.notValidUntil;
      const output =
        notValidUntil && !notValidUntil.includes("0001")
          ? format(new Date(notValidUntil), "MM-dd-yyyy")
          : "*****";

      return <label>{output}</label>;
    }
  },
  {
    title: "Not Valid After(MM-DD-YYYY)",
    dataIndex: "enablement.notValidAfter",
    width: 200,
    render: (_: unknown, record: EnrichedAuthenticationRule) => {
      const notValidAfter = record.enablement.notValidAfter;
      const output =
        notValidAfter && !notValidAfter.includes("9999")
          ? format(new Date(notValidAfter), "MM-dd-yyyy")
          : "*****";

      return <label>{output}</label>;
    }
  }
];
