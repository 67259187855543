/* eslint-disable camelcase */
import { runCarDashboardScript } from "../run-cardashboard-script";
import { createLogger } from "vinsolutions/logger";

const logger = createLogger("vinmanager-inventory-master");

export interface IVinManagerInventoryMaster {
  objectToQueryStringBuilder: (
    queryString: DestinationQueryString,
    delimiterA: string,
    delimiterB: string
  ) => string;
  formatDateTwo: (date: Date) => string;
  formatDate: (date: Date) => string;
  getUuid: () => string;
  SecurityPrincipalILMUserIsManagerOrAdmin?: boolean;
  SelectedDealerProfile_IsCallTrackingBillable?: boolean;
  IsRimsEnabledForTextMessagingWfe?: boolean;
  IsDealerEnabledForThirdPartyMessaging?: boolean;
  IsDealerEnabledForNewCallProviderLog?: boolean;
  IsDealerEmailDomainDiverted?: boolean;
  MailNoHostEnabled?: boolean;
  IsDealerEnabledForAnyFacebookFeature?: boolean;
  HasFacebookGranularPermission?: boolean;
  IsDealerEnabledForFacebookFullIntegration?: boolean;
}

export interface Destination {
  fullPage?: string;
  fullPageQueryString?: DestinationQueryString;
  popPage?: string;
  popQueryString?: DestinationQueryString;
  leftPage?: string;
  leftQueryString?: DestinationQueryString;
  rightPage?: string;
  rightQueryString?: DestinationQueryString;
  selectedTab?: string;
  popWindowName?: string;
  windowFeatures?: string;
}

export interface DestinationQueryString {
  pageDisplayType?: string;
  almType?: string;
  from?: string;
  to?: string;
  urlSettingName?: string;
  type?: string;
  direction?: string;
  callProviderType?: string;
  unmatched?: boolean;
  channel?: string;
  knownCustomer?: string;
  communicationType?: string;
  dateRange?: string;
  start?: string;
  end?: string;
  mp?: boolean;
}
interface vinManagerInventoryMasterWindow extends Window {
  VinManagerInventoryMaster: IVinManagerInventoryMaster;
}

export const getVinManagerInventoryMaster = () => {
  logger.info("getVinManagerInventoryMaster");
  let vinManagerInventoryMaster = {} as IVinManagerInventoryMaster;
  const iFrame: HTMLIFrameElement | null = document.getElementById(
    "cardashboardframe"
  ) as HTMLIFrameElement;
  if (!iFrame) {
    logger.error("cardashboardframe not found");
    return vinManagerInventoryMaster;
  }
  const window: vinManagerInventoryMasterWindow | null =
    iFrame.contentWindow as vinManagerInventoryMasterWindow;
  if (!window) {
    logger.error("CarDashboardFrame contentWindow not found");
    return vinManagerInventoryMaster;
  }
  vinManagerInventoryMaster = window.VinManagerInventoryMaster;

  if (!vinManagerInventoryMaster) {
    logger.error("VinManagerInventoryMaster not found");
  }

  return vinManagerInventoryMaster;
};

export const generateDestinationUrl = function (
  {
    fullPage,
    fullPageQueryString,
    popPage,
    popQueryString,
    leftPage,
    leftQueryString,
    rightPage,
    rightQueryString,
    selectedTab
  }: Destination,
  VinManagerInventoryMaster: IVinManagerInventoryMaster
) {
  logger.info("generateDestinationUrl");
  if (fullPage) {
    let destinationUrl = fullPage;
    if (fullPageQueryString) {
      destinationUrl +=
        "?" +
        VinManagerInventoryMaster.objectToQueryStringBuilder(
          fullPageQueryString,
          "&",
          "="
        );
    }
    return destinationUrl;
  }

  if (popPage) {
    let destinationUrl = popPage;
    if (popQueryString) {
      destinationUrl +=
        "?" +
        VinManagerInventoryMaster.objectToQueryStringBuilder(
          popQueryString,
          "&",
          "="
        );
    }
    logger.info("PopPage destinationUrl", destinationUrl);
    return destinationUrl;
  }

  if (leftPage || rightPage) {
    let destinationUrl =
      "/CarDashboard/Pages/LeadManagement/ActiveLeadsLayout.aspx";
    if (selectedTab) {
      destinationUrl += "?SelectedTab=" + selectedTab;
    } else {
      destinationUrl += "?SelectedTab=t_ILM";
    }
    destinationUrl += "&NoMenu=true";
    if (leftPage) {
      destinationUrl += "&leftpaneframe=" + leftPage;
    } else {
      if (VinManagerInventoryMaster.SecurityPrincipalILMUserIsManagerOrAdmin)
        destinationUrl += "&leftpaneframe=DealerDashboard2.aspx";
    }
    if (rightPage) {
      destinationUrl += "&rightpaneframe=" + rightPage;
    } else {
      if (VinManagerInventoryMaster.SecurityPrincipalILMUserIsManagerOrAdmin)
        destinationUrl += "&rightpaneframe=DealerDashboard1.aspx";
    }
    if (leftQueryString) {
      destinationUrl +=
        "&variableleft=" +
        VinManagerInventoryMaster.objectToQueryStringBuilder(
          leftQueryString,
          "|",
          "|"
        );
    }
    if (rightQueryString) {
      destinationUrl +=
        "&variableright=" +
        VinManagerInventoryMaster.objectToQueryStringBuilder(
          rightQueryString,
          "|",
          "|"
        );
    }
    logger.info("Left/Right destinationUrl", destinationUrl);
    return destinationUrl;
  }

  return "#"; // default;
};

export const navigateToDestination = function (
  {
    fullPage,
    fullPageQueryString,
    popPage,
    popQueryString,
    leftPage,
    leftQueryString,
    rightPage,
    rightQueryString,
    selectedTab,
    popWindowName,
    windowFeatures
  }: Destination,
  setCarDashboardFrameUrl: (url: string) => void,
  VinManagerInventoryMaster: IVinManagerInventoryMaster
) {
  logger.info("navigateToDestination");
  if (!VinManagerInventoryMaster) {
    logger.error("VinManagerInventoryMaster not found");
    return;
  }

  if (fullPage) {
    let destinationUrl = fullPage;
    if (fullPageQueryString) {
      destinationUrl +=
        "?" +
        VinManagerInventoryMaster.objectToQueryStringBuilder(
          fullPageQueryString,
          "&",
          "="
        );
    }
    setCarDashboardFrameUrl(destinationUrl);
  } else if (popPage) {
    let destinationUrl = popPage;
    if (popQueryString) {
      destinationUrl +=
        "?" +
        VinManagerInventoryMaster.objectToQueryStringBuilder(
          popQueryString,
          "&",
          "="
        );
    }
    let finalPopWindow = "VinManagerInventoryMasterPopWindow";
    if (popWindowName) {
      finalPopWindow = popWindowName;
    }
    let finalWindowFeatures = "height=800,width=1200,left=100,top=100'";
    if (windowFeatures) {
      finalWindowFeatures = windowFeatures;
    }
    logger.info("PopPage destinationUrl", destinationUrl);
    window.open(destinationUrl, finalPopWindow, finalWindowFeatures);
  } else if (leftPage || rightPage) {
    const leftpaneframe = runCarDashboardScript({
      functionName: "document.getElementById",
      variable: "leftpaneframe"
    });
    const rightpaneframe = runCarDashboardScript({
      functionName: "document.getElementById",
      variable: "rightpaneframe"
    });
    if (!leftpaneframe || !rightpaneframe) {
      let destinationUrl =
        "/CarDashboard/Pages/LeadManagement/ActiveLeadsLayout.aspx";
      if (selectedTab) {
        destinationUrl += "?SelectedTab=" + selectedTab;
      } else {
        destinationUrl += "?SelectedTab=t_ILM";
      }
      destinationUrl += "&NoMenu=true";
      if (leftPage) {
        destinationUrl += "&leftpaneframe=" + leftPage;
      } else {
        if (VinManagerInventoryMaster.SecurityPrincipalILMUserIsManagerOrAdmin)
          destinationUrl += "&leftpaneframe=DealerDashboard2.aspx";
      }
      if (rightPage) {
        destinationUrl += "&rightpaneframe=" + rightPage;
      } else {
        if (VinManagerInventoryMaster.SecurityPrincipalILMUserIsManagerOrAdmin)
          destinationUrl += "&rightpaneframe=DealerDashboard1.aspx";
      }
      if (leftQueryString) {
        destinationUrl +=
          "&variableleft=" +
          VinManagerInventoryMaster.objectToQueryStringBuilder(
            leftQueryString,
            "|",
            "|"
          );
      }
      if (rightQueryString) {
        destinationUrl +=
          "&variableright=" +
          VinManagerInventoryMaster.objectToQueryStringBuilder(
            rightQueryString,
            "|",
            "|"
          );
      }
      setCarDashboardFrameUrl(destinationUrl);
    } else {
      if (leftPage) {
        let destinationUrl = leftPage;
        if (leftQueryString) {
          destinationUrl +=
            "?" +
            VinManagerInventoryMaster.objectToQueryStringBuilder(
              leftQueryString,
              "&",
              "="
            );
        }
        runCarDashboardScript({
          functionName: "LoadFrame",
          variable: ["leftpaneframe", destinationUrl]
        });
      }
      if (rightPage) {
        let destinationUrl = rightPage;
        if (rightQueryString) {
          destinationUrl +=
            "?" +
            VinManagerInventoryMaster.objectToQueryStringBuilder(
              rightQueryString,
              "&",
              "="
            );
        }
        runCarDashboardScript({
          functionName: "LoadFrame",
          variable: ["rightpaneframe", destinationUrl]
        });
      }
    }
  }
};
