import { TagManager } from "@vinsolutions/core-third-party-gtm";
import { createDeskFromDxg } from "@vinsolutions/data-access/desking.api";
import { DealApplicationEventParams } from "./deal-application-event";

const sendLaunchApplicationAnalytics = () => {
  TagManager.event({
    event: "userEvent",
    eventElement: "QPProposals",
    eventAction: "click",
    eventResult: "clicked",
    eventLocation: "AccelerateCopyToDesking"
  });
};

export const CopyToDeskingEvent = function (
  params: DealApplicationEventParams
) {
  return new Promise<void>(() => {
    sendLaunchApplicationAnalytics();

    createDeskFromDxg(
      params.dealExchangeDealId,
      params.dealExchangeVersionId,
      params.jwt ?? ""
    ).then(({ deskCreated, hasError, errorMessage }) => {
      if (deskCreated === true) {
        createCookie("deskAutoLeadId", params.leadId);

        const win = window.open(params.applicationUrl, "_blank");
        if (win) {
          win.focus();
        }
      } else if (hasError && params?.onApplicationError) {
        params.onApplicationError("Copy to Desking failed", errorMessage);
      }
    });
  });
};

const createCookie = (
  name: string,
  value: number | undefined,
  days?: number
) => {
  let expires = "";
  if (days) {
    const date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = "; expires=" + date.toUTCString();
  }
  document.cookie =
    escape(name) +
    "=" +
    escape(value ? value.toString() : "") +
    expires +
    "; path=/";
};
