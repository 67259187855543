/* eslint-disable camelcase */
import { useEffect } from "react";
import { shallowEqual, useSelector } from "react-redux";
// eslint-disable-next-line @nx/enforce-module-boundaries
import { getProfileState } from "@vinsolutions/ccrm/store";
// eslint-disable-next-line @nx/enforce-module-boundaries
import { useVinconnectFlagEnabled } from "@vinsolutions/core/third-party/launch-darkly";
// eslint-disable-next-line @nx/enforce-module-boundaries
import { OidcUser, parseAccessToken } from "@vinsolutions/tenant/jwt-utils";
// eslint-disable-next-line @nx/enforce-module-boundaries
import { useReactOidc } from "@axa-fr/react-oidc-context";
// eslint-disable-next-line @nx/enforce-module-boundaries
import { getConfig } from "@vinsolutions/core/config";
// eslint-disable-next-line @nx/enforce-module-boundaries
import { usePrevious } from "@vinsolutions/ccrm/util";

declare global {
  interface Window {
    pendo: any;
  }
}

export const Pendo = () => {
  const { oidcUser } = useReactOidc();

  const {
    dealerId,
    dealerName,
    isVinEmployee,
    profileLoadingStatus,
    userFullName,
    userRole,
    userId
  } = useSelector(getProfileState, shallowEqual);

  const previousDealerId = usePrevious(dealerId, null);

  const pendoToggle = useVinconnectFlagEnabled("nx.vinconnect.pendo");
  const accessToken = parseAccessToken(oidcUser as unknown as OidcUser);
  const pendoApiId = getConfig().pendoApiId ?? "";

  useEffect(() => {
    let reloadPendo = false;

    if (dealerId !== null && previousDealerId !== dealerId) {
      reloadPendo = true;
    }

    if (pendoToggle && accessToken) {
      if (
        (profileLoadingStatus && profileLoadingStatus === "loaded") ||
        reloadPendo
      ) {
        const pendoScript = document.getElementById("pendo-script-tag");
        if (!pendoScript) {
          const pendo = document.createElement("script");
          pendo.id = "pendo-script-tag";
          pendo.innerHTML = `
                (function(apiKey){
                        (function(p,e,n,d,o){var v,w,x,y,z;o=p[d]=p[d]||{};o._q=o._q||[];   
                        v=['initialize','identify','updateOptions','pageLoad','track'];for(w=0,x=v.length;w<x;++w)(function(m){       
                        o[m]=o[m]||function(){o._q[m===v[0]?'unshift':'push']([m].concat([].slice.call(arguments,0)));};})(v[w]);   
                        y=e.createElement(n);y.async=!0;y.src='https://cdn.pendo.io/agent/static/${pendoApiId}/pendo.js';       
                        z=e.getElementsByTagName(n)[0];z.parentNode.insertBefore(y,z);})(window,document,'script','pendo');

                    pendo.initialize({
                        visitor: {
                            id: '${accessToken.bridge_platform_id}',
                            full_name: '${userFullName}',
                            first_name: '${accessToken.given_name}',
                            last_name: '${accessToken.family_name}',
                            internal: ${isVinEmployee},
                            vin_userrole: '${userRole}'
                        },
                        account: {
                            id: 'vin_${dealerId}',
                            account_name: '${dealerName}'
                        }
                    });

                })('${pendoApiId}');`;

          const head = document.getElementsByTagName("head")[0];
          head.appendChild(pendo);
        } else {
          if (reloadPendo) {
            if (window?.pendo?.identify) {
              window.pendo.identify({
                visitor: {
                  id: accessToken.bridge_platform_id,
                  full_name: userFullName,
                  first_name: accessToken.given_name,
                  last_name: accessToken.family_name,
                  internal: isVinEmployee,
                  vin_userrole: userRole
                },
                account: {
                  id: `vin_${dealerId}`,
                  account_name: dealerName
                }
              });
            } else {
              console.log("Pendo script is not loaded yet");
            }
          }
        }
      }
    } else {
      const pendoScript = document.getElementById("pendo-script-tag");
      if (pendoScript) {
        pendoScript.remove();
      }
    }
  }, [
    pendoToggle,
    userId,
    dealerId,
    dealerName,
    isVinEmployee,
    userFullName,
    userRole,
    profileLoadingStatus
  ]);

  return null;
};
