import { useReactOidc } from "@axa-fr/react-oidc-context";
import { Box } from "@interstate/components/Box";
import { Button } from "@interstate/components/Button";
import {
  ComboBox,
  ComboBoxEventValue,
  ComboBoxOptions
} from "@interstate/components/ComboBox";
import { InterstateOnChangeEvent } from "@interstate/components/InterstateEvents";
import { getProfileState } from "@vinsolutions/ccrm/store";
import { useNewRelicInteraction } from "@vinsolutions/core-third-party-newrelic";
import {
  CustomerSettings,
  DealerSettings,
  LeadInformation,
  UserPermissions,
  VehicleInformation,
  getCustomerSettings,
  getDealerSettings,
  getLeadInformation,
  getProcesses,
  getUserPermissions,
  getVehicleInformation,
  setMappedResults
} from "@vinsolutions/data-access/lead/crm.lead.bff";
import { useCallback, useEffect, useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import LeadPanelLeadInformation from "./components/lead-information";
import LeadList from "./components/lead-list";
import LeadPanelCtas from "./components/lead-panel-ctas";
import LeadPanelVehicleInformation from "./components/vehicle-information";
import { AcquisitionLeadPanelProps } from "./interfaces/acquisition-lead-panel-props";

/**
 * Hides a third div that throws off styling. This will be fixed in Interstate 1.1.0, so this can potentially be removed after that version
 */
const InterstateInputStyleFix = styled.div`
  &&& div.ids-text-input-container.MuiBox-root,
  div.ids-combo-box-root-container.MuiBox-root,
  div.interstate-select-input-root.MuiBox-root {
    div.MuiGrid2-container
      div.MuiGrid2-root.MuiGrid2-direction-xs-row:nth-child(3) {
      display: none;
    }
  }
`;

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function getValue(
  options: ComboBoxOptions,
  selectedValue: string | number
): ComboBoxOptions | undefined {
  const selectedOption = options.find(
    s => s.value === selectedValue.toString()
  );

  return selectedOption ? [selectedOption] : undefined;
}

export function AcquisitionLeadPanel({
  customerId: stateCustomerId,
  selectedLeadId: stateSelectedLeadId
}: AcquisitionLeadPanelProps) {
  const query = useQuery();

  const { endInteraction, setInteractionAttributes } = useNewRelicInteraction(
    "PageLeadPanel",
    "Loading Lead Panel"
  );

  const [customerId, setCustomerId] = useState<number>(stateCustomerId || 0);
  const [selectedLeadId, setSelectedLeadId] = useState<number>(
    stateSelectedLeadId || 0
  );

  const [processes, setProcesses] = useState<ComboBoxOptions>([]);
  const [process, setProcess] = useState<number>(1);
  const [userPermissions, setUserPermissions] = useState<UserPermissions>();

  const [vehicleInformation, setVehicleInformation] =
    useState<VehicleInformation>({} as VehicleInformation);

  const [leadInformation, setLeadInformation] = useState<LeadInformation>(
    {} as LeadInformation
  );

  const [dealerSettings, setDealerSettings] = useState<DealerSettings>(
    {} as DealerSettings
  );

  const [customerSettings, setCustomerSettings] = useState<CustomerSettings>(
    {} as CustomerSettings
  );

  const {
    dealerId: profileDealerId,
    userId: profileUserId,
    userFullName
  } = useSelector(getProfileState, shallowEqual);
  const oidcUser = useReactOidc().oidcUser;

  if (selectedLeadId <= 0) {
    const queryLeadId = +(
      query.get("leadId") ||
      query.get("AutoLeadID") ||
      "0"
    );

    if (queryLeadId > 0) {
      setSelectedLeadId(queryLeadId);
    }
  }

  if (customerId <= 0) {
    const queryCustomerId = +(
      query.get("customerId") ||
      query.get("GlobalCustomerID") ||
      "0"
    );

    if (queryCustomerId > 0) {
      setCustomerId(queryCustomerId);
    } else {
      throw new Error("customerId parameter is required");
    }
  }

  const dealerId = profileDealerId || 0;
  const userId = profileUserId || 0;

  setInteractionAttributes({
    customerId,
    dealerId,
    leadId: selectedLeadId
  });

  const loadDealerSettings = useCallback(async () => {
    const result =
      oidcUser && dealerId > 0
        ? await getDealerSettings(dealerId, oidcUser.access_token, true)
        : null;

    if (result) {
      setDealerSettings(result);
    }
  }, [dealerId, oidcUser, setDealerSettings]);

  const loadCustomerSettings = useCallback(async () => {
    const result =
      oidcUser && customerId > 0
        ? await getCustomerSettings(customerId, oidcUser.access_token, true)
        : null;

    if (result) {
      setCustomerSettings(result);
    }
  }, [customerId, oidcUser, setCustomerSettings]);

  const loadUserPermissions = useCallback(async () => {
    const result =
      oidcUser && dealerId > 0 && userId > 0
        ? await getUserPermissions(
            dealerId,
            userId,
            oidcUser.access_token,
            true
          )
        : null;

    if (result) {
      setUserPermissions(result);
    }
  }, [dealerId, oidcUser, userId, setUserPermissions]);

  const loadProcesses = useCallback(async () => {
    const result =
      oidcUser && dealerId > 0
        ? await getProcesses(dealerId, oidcUser.access_token, true)
        : null;

    setMappedResults(setProcesses, result);
  }, [dealerId, oidcUser, userId, setUserPermissions]);

  const loadVehicle = useCallback(async () => {
    const result =
      oidcUser && selectedLeadId > 0
        ? await getVehicleInformation(
            selectedLeadId,
            oidcUser.access_token,
            true
          )
        : null;

    if (result) {
      setVehicleInformation(result);
    }
  }, [selectedLeadId, oidcUser, setVehicleInformation]);

  const loadLead = useCallback(async () => {
    const result =
      oidcUser && selectedLeadId > 0
        ? await getLeadInformation(selectedLeadId, oidcUser.access_token, true)
        : null;

    if (result) {
      setLeadInformation(result);
    }
  }, [selectedLeadId, oidcUser, setLeadInformation]);

  useEffect(() => {
    loadLead();
    loadVehicle();
  }, [selectedLeadId, loadLead, loadVehicle]);

  useEffect(() => {
    loadCustomerSettings();
  }, [customerId, loadCustomerSettings]);

  useEffect(() => {
    loadDealerSettings();

    loadUserPermissions();

    loadProcesses();
  }, [loadDealerSettings, loadProcesses, loadUserPermissions]);

  const handlePageLoad = useCallback(() => {
    endInteraction();
  }, [endInteraction]);

  // TODO: Not working, fix it
  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window.addEventListener("load", handlePageLoad);

    return () => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      window.removeEventListener("load", handlePageLoad);
    };
  }, [handlePageLoad]);

  function onProcessChange(
    event: InterstateOnChangeEvent<ComboBoxEventValue>,
    options: ComboBoxOptions
  ) {
    if (
      !event.isValid ||
      !event.target.value ||
      !Array.isArray(event.target.value) ||
      event.target.value.length === 0 ||
      !event.target.value[0].value
    ) {
      return;
    }

    const id = options.find(
      obj => obj.value === event.target.value[0].value
    )?.value;

    const value = id ? +id : undefined;

    if (value) {
      setProcess(value);
    }
  }

  const { userRole } = useSelector(getProfileState, shallowEqual);

  const isInternalEmployee = userRole === "VinSolutions Employee";

  const isAuditInfoVisible = userRole === "Admin" || isInternalEmployee;

  // build audit information: Customer ID | [Lead ID] | Dealer ID

  const auditCustomerId = `Customer ID: ${customerId}`;

  const leadId = leadInformation?.id ?? 0;
  const isValidLeadId = leadId > 0;
  const auditLeadId = isValidLeadId ? `Lead ID: ${leadId}` : "";

  const auditDealerId = `Dealer ID: ${dealerId}`;

  const joinedAuditIds = [auditCustomerId, auditLeadId, auditDealerId]
    // exclude lead ID when it's undefined
    .filter(s => s !== "")
    .join(" | ");

  const isViewLogVisible = isInternalEmployee && isValidLeadId;

  function onViewLogClicked() {
    window.open(
      `/CarDashboard/Pages/Admin/LogTable/ViewLogTable.html?tableName=AutoLead&primaryKey=${leadId}&recordCount=50`,
      "_blank"
    );
  }

  return (
    <InterstateInputStyleFix>
      <Box padding={"1em"}>
        <LeadList
          customerId={customerId}
          dealerId={dealerId}
          jwt={oidcUser.access_token}
          selectedLeadId={selectedLeadId}
          setSelectedLeadId={setSelectedLeadId}
        />

        {leadInformation?.id && leadInformation.id > 0 && (
          <Box paddingLeft={"0.5em"} paddingRight={"0.5em"} paddingTop={"1em"}>
            {userPermissions?.canViewProcesses &&
              leadInformation.status === 1 && (
                <ComboBox
                  allowNewEntry={false}
                  label={"Process"}
                  name={"process-input"}
                  options={processes}
                  size="small"
                  value={getValue(processes, process)}
                  onChange={event => onProcessChange(event, processes)}
                />
              )}

            <LeadPanelCtas
              customerConsentStatus={customerSettings.consentStatus}
              customerHasValidEmailAddress={
                customerSettings.hasValidEmailAddress
              }
              customerId={customerId}
              dealerHasCASLCompliance={dealerSettings.hasCASLCompliance}
              dealerHasFlickFusion={dealerSettings.hasFlickFusion}
              dealerId={dealerId}
              leadId={leadInformation.id}
              leadStatus={leadInformation.status}
              userId={userId}
              userName={userFullName}
            />

            <Box
              columnGap={"4em"}
              display={"grid"}
              gridTemplateColumns={"1fr 1fr"}
              paddingTop={"2.5em"}
            >
              <LeadPanelLeadInformation
                dealerId={dealerId}
                jwt={oidcUser.access_token}
                leadInfromation={leadInformation}
              />

              <LeadPanelVehicleInformation
                isAcquired={leadInformation.status === 2}
                leadId={leadInformation.id}
                vehicleInformation={vehicleInformation}
              />
            </Box>
          </Box>
        )}

        {isAuditInfoVisible && (
          <Box display={"grid"} gap={"0.5em"}>
            <Box>
              {isViewLogVisible && (
                <Button
                  buttonStyle="secondary"
                  size="small"
                  type="button"
                  onClick={() => onViewLogClicked()}
                >
                  View Log
                </Button>
              )}
            </Box>

            <Box>{joinedAuditIds}</Box>
          </Box>
        )}
      </Box>
    </InterstateInputStyleFix>
  );
}
