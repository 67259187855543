import useDealInteractionDropdown from "../hooks/useDealInteractionDropdown";
import { DealInteractionProps } from "../interfaces/deal-interaction-props";
import { Dropdown } from "@vinsolutions/core-cx";
import styled from "styled-components";

const StyledDropdown = styled(Dropdown)`
  vertical-align: top;
`;
export const DealInteraction = (props: DealInteractionProps) => {
  const result = useDealInteractionDropdown(props);
  if (result === null || result.options?.length <= 0) {
    return null;
  }

  return (
    <StyledDropdown
      buttonStyle="secondary"
      disabled={!result.enabled}
      htmlId="dealInteractionDropdown"
      name="dealInteractionDropdown"
      options={result.options}
    >
      Deal Central
    </StyledDropdown>
  );
};
