import { useVinconnectFlagEnabled } from "@vinsolutions/core-third-party-launch-darkly";
// eslint-disable-next-line @nx/enforce-module-boundaries
import { OverlayPosition, useOverlay } from "@vinsolutions/feature/overlays";
// eslint-disable-next-line @nx/enforce-module-boundaries
import { UnifiedInboxOverlayEvent } from "@vinsolutions/sub-feature/unified-inbox";
import { useCallback, useEffect } from "react";

export const useUnifiedInboxOverlayEvents = () => {
  const { openOverlay } = useOverlay();
  const isEnabled = useVinconnectFlagEnabled("nx.vinconnect.show-comms-inbox");

  const handleUnifiedInboxOverlayEvent = useCallback(
    ({ detail: { action, data } }: CustomEvent<UnifiedInboxOverlayEvent>) => {
      if (!isEnabled || action !== "Open" || !data) {
        return;
      }

      const { autoLeadId, customerId, defaultTab, overlayPosition } = data;
      openOverlay(
        "CustomerInbox",
        overlayPosition as unknown as OverlayPosition,
        {
          autoLeadId,
          customerId,
          defaultTab
        }
      );
    },
    [isEnabled, openOverlay]
  );

  useEffect(() => {
    window.addEventListener(
      "unifiedInboxOverlayEvent",
      handleUnifiedInboxOverlayEvent as EventListener,
      true
    );

    return () => {
      window.removeEventListener(
        "unifiedInboxOverlayEvent",
        handleUnifiedInboxOverlayEvent as EventListener,
        true
      );
    };
  }, [handleUnifiedInboxOverlayEvent]);
};

export default useUnifiedInboxOverlayEvents;
