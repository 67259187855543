const DealInteractionIconSmall = () => {
  return (
    <svg
      className="accelerate-icon"
      height="25px"
      version="1.1"
      viewBox="0 0 25 25"
      width="25px"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>logo/accelerate small small</title>
      <defs>
        <polygon
          id="path-1"
          points="0 0 6.44179256 0 6.44179256 5.97567007 0 5.97567007"
        />
        <polygon
          id="path-3"
          points="0 0 9.98535979 0 9.98535979 8.49811343 0 8.49811343"
        />
        <polygon
          id="path-5"
          points="0 -1.40190211e-15 2.1209375 -1.40190211e-15 2.1209375 10.54602 0 10.54602"
        />
      </defs>
      <g
        fill="none"
        fillRule="evenodd"
        id="logo/accelerate-small-small"
        stroke="none"
        strokeWidth="1"
      >
        <g id="Group-18" transform="translate(0.000000, 2.000000)">
          <path
            d="M10.3139901,10 L4,21 L6.6522717,21 L8.08726946,18.3444379 L12.0508322,18.3444379 L12.4137052,21 L15,21 L13.1707902,10 L10.3139901,10 Z M11.308592,12.3036396 L11.8281601,16.3507485 L9.09836557,16.3507485 L11.308592,12.3036396 Z"
            fill="#00538F"
            id="Fill-1"
          />
          <g id="Group-17">
            <path
              d="M7.0190625,6.95932986 L4.3628125,4.17818649 C4.333125,4.14661846 4.2925,4.12925605 4.2503125,4.12925605 C4.208125,4.12925605 4.1659375,4.14661846 4.13625,4.17660809 C2.6596875,5.67608947 1.5534375,7.4407423 0.845625,9.42321453 C0.8315625,9.46267456 0.833125,9.5068698 0.8503125,9.54632984 C0.8690625,9.58421148 0.901875,9.6142011 0.9425,9.62682832 L2.2128125,10.0703591 C2.2315625,10.0766727 2.251875,10.0798295 2.270625,10.0782511 C2.3253125,10.0750943 2.37375,10.0451047 2.4003125,9.99459585 C2.651875,9.52581062 2.9378125,9.0680742 3.2503125,8.6355922 C3.7221875,7.9789772 4.2628125,7.36813583 4.8565625,6.81727372 C4.9971875,6.68784481 5.1378125,6.56315109 5.28,6.44319258 L6.8346875,7.21345249 C6.9034375,7.24817732 6.9878125,7.2276581 7.033125,7.16452205 C7.0784375,7.10138599 7.0721875,7.01457391 7.0190625,6.95932986"
              fill="#00538F"
              id="Fill-2"
            />
            <path
              d="M10.7254688,0.582587977 C10.7160938,0.539971138 10.6910938,0.503667904 10.6535938,0.481570284 C10.6176563,0.457894262 10.5723438,0.451580656 10.5317188,0.464207868 C8.44578125,1.01033477 6.56453125,2.00630609 4.93796875,3.42213219 C4.90671875,3.44896502 4.88640625,3.49000346 4.88484375,3.5326203 C4.88171875,3.57523714 4.89734375,3.61943238 4.92546875,3.649422 L6.50046875,5.38724201 C6.55203125,5.44248606 6.63640625,5.45511327 6.70046875,5.41565323 C7.03953125,5.20414744 7.39265625,5.01000406 7.75046875,4.8332231 L7.75046875,4.8332231 C8.62390625,4.4070547 9.55203125,4.08979601 10.5035938,3.88933903 L11.4520313,5.15521699 C11.4848438,5.19941223 11.5364063,5.22150985 11.5879688,5.21993145 C11.6067188,5.21835305 11.6270313,5.21361785 11.6457813,5.20572584 C11.7129688,5.17257941 11.7504688,5.09681614 11.7332813,5.02263127 L10.7254688,0.582587977 Z"
              fill="#00538F"
              id="Fill-4"
            />
            <g id="Group-8" transform="translate(11.442166, 0.000000)">
              <mask fill="white" id="mask-2">
                <use xlinkHref="#path-1" />
              </mask>
              <g id="Clip-7" />
              <path
                d="M6.42049067,0.528606647 C6.39861567,0.492303414 6.36424067,0.463892188 6.32361567,0.454421779 C4.30330317,-0.0648722998 2.16111567,-0.14063557 0.129865666,0.231867174 C0.0861156661,0.23818078 0.0486156661,0.265013604 0.0251781661,0.301316838 C0.00174066612,0.339198473 -0.00607183388,0.384972115 0.00486566612,0.429167356 L0.784553166,3.52914781 C0.803303166,3.60333268 0.870490666,3.65384153 0.947053166,3.65068473 C2.10955317,3.59070547 3.27361567,3.70592878 4.40799067,3.98846264 L4.58299067,5.83045714 C4.59080317,5.90779881 4.65017817,5.96777806 4.72674067,5.97567007 L4.75017817,5.97567007 C4.81580317,5.97251327 4.87361567,5.92673962 4.89236567,5.86202516 C5.04861567,5.33641748 6.41892817,0.714858019 6.43611567,0.650143559 C6.44705317,0.610683523 6.44236567,0.564909881 6.42049067,0.528606647"
                fill="#00538F"
                id="Fill-6"
                mask="url(#mask-2)"
              />
            </g>
            <path
              d="M17.8115625,4.6641763 C18.4959375,4.96880778 19.6521875,5.78484133 20.513125,6.43356433 L20.531875,6.41935872 C20.994375,6.09420802 21.4553125,5.76905732 21.91625,5.44390662 C22.625625,4.94513176 23.3521875,4.43215129 24.07875,3.92390602 C24.069375,3.91443561 24.06,3.9065436 24.050625,3.89707319 C22.6021875,2.54280474 20.919375,1.5184222 19.050625,0.847601581 C19.01,0.833395968 18.96625,0.836552771 18.9271875,0.855493589 C18.888125,0.876012808 18.86,0.909159238 18.8475,0.948619275 L17.725625,4.46845452 C17.700625,4.54737459 17.738125,4.63102987 17.8115625,4.6641763"
              fill="#00538F"
              id="Fill-9"
            />
            <g id="Group-13" transform="translate(15.014640, 4.073065)">
              <mask fill="white" id="mask-4">
                <use xlinkHref="#path-3" />
              </mask>
              <g id="Clip-12" />
              <path
                d="M1.32301604,8.45233979 C1.53395354,8.37815492 1.69645354,8.22189318 1.85270354,8.06878824 L9.96832854,0.181516167 C9.97457854,0.176780963 9.97926604,0.167310554 9.98551604,0.160996948 C9.94020354,0.104174496 9.88864104,0.052087248 9.82614104,0.00789200728 L9.80895354,0 C8.47770354,0.924943253 7.15426604,1.86567052 5.82770354,2.79850578 C4.12457854,3.99493408 2.42145354,5.19294079 0.718328537,6.38936909 C0.504266037,6.54089563 0.282391037,6.69715737 0.144891037,6.91813358 C-0.0644839631,7.24959788 -0.0488589631,7.72153992 0.204266037,8.06563144 C0.399578537,8.33080288 0.733953537,8.49811343 1.05114104,8.49811343 C1.14489104,8.49811343 1.23707854,8.48390782 1.32301604,8.45233979"
                fill="#00538F"
                id="Fill-11"
                mask="url(#mask-4)"
              />
            </g>
            <g id="Group-16" transform="translate(0.000000, 10.453980)">
              <mask fill="white" id="mask-6">
                <use xlinkHref="#path-5" />
              </mask>
              <g id="Clip-15" />
              <path
                d="M2.1209375,10.54602 C0.669375,7.45866679 0.5584375,3.81255943 1.8990625,0.597355667 C1.91625,0.556317229 1.91625,0.510543587 1.8975,0.469505149 C1.87875,0.428466712 1.844375,0.396898682 1.80375,0.384271471 L0.6896875,0.00861192448 C0.6490625,-0.00559368862 0.60375,-0.00243688571 0.5646875,0.0196607347 C0.525625,0.0401799536 0.4975,0.076483187 0.488125,0.119100026 C-0.4509375,3.64682728 -0.0165625,7.37501152 1.6271875,10.54602 L2.1209375,10.54602 Z"
                fill="#00538F"
                id="Fill-14"
                mask="url(#mask-6)"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default DealInteractionIconSmall;
