import { useDispatch, useSelector } from "react-redux";
import { Tooltip } from "@interstate/components/Tooltip";
import { TrashIcon } from "@interstate/components/Icons";
import { EnrichedPermission } from "../../types/authSettings.type";
import { AuthSettingsButton } from "../auth-settings/authSettings.styles.component";
import { ERROR_MSG_ON_DELETE_PERMISSIONS } from "../../constants";
import {
  useAuthSettingsApp,
  useConductorRuleSet
} from "../../hooks/useAuthSettings.hook";
import {
  getAuthSettingsState,
  setConductorRuleSet,
  setModalContent
} from "../../slices/auth-settings";

export const AuthSettingsPermissionsDeleteButton = ({
  permission
}: {
  permission: EnrichedPermission;
}) => {
  const dispatch = useDispatch();
  const { putRuleSet } = useAuthSettingsApp();
  const { isAddNewOem } = useSelector(getAuthSettingsState);
  const { conductorRuleSet } = useConductorRuleSet(permission.parentId);
  const isDisabled = conductorRuleSet?.permissions.length === 1;

  const updateRuleSet = () => {
    if (conductorRuleSet) {
      return {
        ...conductorRuleSet,
        permissions: conductorRuleSet.permissions.filter(
          conductorPermission =>
            conductorPermission.action !== permission.action
        )
      };
    }
  };

  const removePermissions = () => {
    const updatedRuleSet = updateRuleSet();

    if (!updatedRuleSet) return;

    if (isAddNewOem) {
      dispatch(setConductorRuleSet(updatedRuleSet));
    } else {
      putRuleSet(updatedRuleSet);
    }
  };

  const handleOnClick = () => {
    dispatch(
      setModalContent({
        header: "Delete Permission",
        isModalOpen: true,
        primary: {
          action: removePermissions,
          label: "Delete"
        },
        content: (
          <label>
            Are you sure you want to remove <b>{permission.action}</b>?
          </label>
        )
      })
    );
  };

  const deleteButtonComponent = (
    <AuthSettingsButton
      buttonStyle="secondary"
      disabled={isDisabled}
      icon={<TrashIcon />}
      id="oem-auth-settings-action-delete-permission"
      size="small"
      onClick={handleOnClick}
    />
  );

  return isDisabled ? (
    <Tooltip
      id="oem-auth-settings-permissions-delete-tooltip"
      position="top"
      size="large"
      toolTipContent={ERROR_MSG_ON_DELETE_PERMISSIONS}
    >
      {deleteButtonComponent}
    </Tooltip>
  ) : (
    deleteButtonComponent
  );
};
