import { TopPortalStyleProvider } from "@vinsolutions/utility/portals";
import { ModalDialog } from "@vinsolutions/core-cx";
import styled from "styled-components";
import { useEffect } from "react";

const StyledInteractionModalContent = styled.iframe`
  width: 100%;
  height: 100%;
  border: 0px;
`;
const StyledModalDialog = styled(ModalDialog)`
  padding-top: 170px;
  .modal-dialog.modal-dialog {
    width: 650px;
    height: 406.84px;
    .modal-content.modal-content {
      background-color: #fafafa;
      .modal-header {
        padding: 8px 16px 0 16px;
        .close {
          margin-top: 2px;
        }
      }
      .modal-body {
        height: 376px;
        paddding: 0px;
      }
    }
  }
`;

export interface DealModalProps {
  url: string;
  showModal: boolean;
  onHide: () => void;
  width?: string;
  height?: string;
  modalBodyHeight?: string;
}

const DealInteractionModal = ({ url, showModal, onHide }: DealModalProps) => {
  const handleIframeLoad = () => {
    // This function is required for the correct rendering of the legacy Digital Retail WFE functionality.
    const { contentDocument } = document.getElementById(
      "deal-interaction-iframe-id"
    ) as HTMLIFrameElement;
    if ("MutationObserver" in window) {
      const observer = new MutationObserver(() => {
        if (contentDocument) {
          const btnClose = contentDocument.getElementById("btnClose");
          if (btnClose) {
            btnClose.addEventListener("click", onHide);
          }
          const svgClose = contentDocument.getElementById(
            "copylink-close-button"
          );
          if (svgClose) {
            svgClose.remove();
          }
          const gridContainer = contentDocument.querySelector(
            "#CxComponent_rims > div.grid-container"
          ) as HTMLElement;
          if (gridContainer) {
            gridContainer.style.removeProperty("padding-top");
          }
          if (btnClose || svgClose) {
            observer.disconnect();
          }
        }
      });
      if (contentDocument) {
        observer.observe(contentDocument.body, {
          childList: true,
          subtree: true
        });
      }
    }
  };

  useEffect(() => {
    document.addEventListener("visibilitychange", onHide);
    return () => {
      document.removeEventListener("visibilitychange", onHide);
    };
  }, [onHide]);

  return (
    <TopPortalStyleProvider cacheKey="deal-interaction-modal">
      <StyledModalDialog
        data-testid="deal-interaction-modal-dialog"
        htmlId="deal-interaction-modal-dialog"
        show={showModal}
        onHide={onHide}
      >
        <StyledInteractionModalContent
          data-testid="deal-interaction-iframe-id"
          id="deal-interaction-iframe-id"
          src={url}
          onLoad={handleIframeLoad}
        />
      </StyledModalDialog>
    </TopPortalStyleProvider>
  );
};

export default DealInteractionModal;
